import React, { useState, useEffect } from "react";
import { Form , Stack, Accordion, Container, Row, Col, Alert, Modal, Button } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import "./BillingForm.css";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import TermsOfPrivacy from "./TermsOfPrivacy";
import TermsOfAutoBilling from "./TermsOfAutoBilling";
import { useAppContext } from "../libs/contextLib";
import ToggleSwitch from "./ToggleSwitch";
import { useForm } from 'react-hook-form';

function BillCompleteModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('BILL_TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('BILL_STATUS')}</h4>
        <p>
          {t('BILL_RESULT_OK')}<br/>
          {t('BILL_RESULT_ETC')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function BillingForm() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [isSwitchOnAutoBilling, setIsSwitchOnAutoBilling] = useState(false);
  const [isSwitchOnPrivate, setIsSwitchOnPrivate] = useState(false);
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { setTicketGubunAppContext } = useAppContext();
  const { setStrRoundDateAppContext } = useAppContext();
  const { setEndRoundDateAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setEndSingleDateAppContext } = useAppContext();

  const { register, handleSubmit, formState, getValues, setValue } = useForm();
  const { isSubmitting } = formState;

  const onSwitchActionAutoBilling = () => {
    setIsSwitchOnAutoBilling(!isSwitchOnAutoBilling);
    //console.log(isSwitchOnAutoBilling);
  };

  const onSwitchActionPrivate = () => {
    setIsSwitchOnPrivate(!isSwitchOnPrivate);
    //console.log(isSwitchOnPrivate);
  };
  
  const onChangeBillingForm = () => {
    const regex = /^[0-9\b -]{0,13}$/;
    var f_data = getValues()

    setValue("card_number", f_data.card_number.replace(/-/g, '').replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4'))
    //setValue("expiry", f_data.expiry.replace(/\//g, '').replace(/(\d{2})(\d{2})/, '$1/$2'))
    setValue("expiry", f_data.expiry.replace(/-/g, '').replace(/(\d{4})(\d{2})/, '$1-$2'))
    return;
  }

  async function onSubmit(data) {

    await axiosInstance.post(`/robotaxi/bookingcarpay_nice_onetime/`, {
      merchant_uid: location.state.merchant_uid,
      amount: location.state.amount,
      card_number: data.card_number, // 카드 번호
      expiry: data.expiry, // 카드 유효기간
      birth: data.birth, // 생년월일
      pwd_2digit: data.pwd_2digit // 카드 비밀번호 앞 두자리
    }).then(function(response) {
      showModal();
    }).catch(function (error) {
      if (error && error.response && error.response.status) {
        if ( error.response.status === 412 ) {
          onError(t('PROCESS_FAIL') + ' : ' + error.response.data);
        } else {
          onError(t('PROCESS_FAIL'));
        }
      } else {
        onError(t('PROCESS_FAIL'));
      }
      //not nessary - deletePayError(imp_uid, merchant_uid, rsp);
      //history.push("/bookingcar");
      history.replace({
        pathname: '/billingform',
        state: {
          merchant_uid: location.state.merchant_uid,
          amount: location.state.amount
        }
      });
    });
     
  }

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
    
    setTicketGubunAppContext("S");
    setStrRoundDateAppContext("");
    setEndRoundDateAppContext("");
    setStrSingleDateAppContext("");
    setEndSingleDateAppContext("");

    history.push("/bookingcar");
  }

  return (
  <Stack gap={3}>
    <div className=" border pt-3 px-2">
      {/* alerting of errors */}
      { ( !formState.isValid && formState.isSubmitted) ?
            <Alert variant="danger"   >
              {Object.values(formState.errors).map( (e,idx) => {
                return (<p key={idx}>{e.message}</p>)
              })}
            </Alert>
          :
            <Alert variant="success">카드정보를 입력해 주세요</Alert>
      }
      <Form className="BillingForm" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group controlId="card_number">
            <Form.Label>카드 번호</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="XXXXXXXXXXXXXXXX"
              {...register("card_number",
                {
                  required: {
                    value: true,
                    message: "카드번호를 입력해 주세요"
                  }, 
                  minLength: {
                    value: 19,
                    message: "카드번호는 16자리 입니다."
                  }, 
                  maxLength: {
                    value: 19,
                    message: "카드번호는 16자리 입니다."
                  },
                  onChange: () => {onChangeBillingForm()}
                } )}
            />
          </Form.Group>
          <Form.Group controlId="birth">
            <Form.Label>생년월일(YYMMDD)/사업자등록번호</Form.Label>
            <Form.Control
              required
              type="number"
              {...register("birth",
                {
                  required: {
                    value: true,
                    message: "개인카드:생년월일(YYMMDD)/법인카드:사업자등록번호"
                  }, 
                  pattern: {
                    value: /^[0-9+-]+$/,
                    message: "숫자만 입력해 주세요"
                  },
                  onChange: () => {onChangeBillingForm()}
                } )}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="expiry">
              <Form.Label>카드 유효기간</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="YYYYMM"
                {...register("expiry",
                  {
                    required: {
                      value: true,
                      message: "카드 유효기간를 입력해 주세요"
                    }, 
                    minLength: {
                      value: 7,
                      message: "카드 유효기간은 6자리 숫자입니다.."
                    }, 
                    maxLength: {
                      value: 7,
                      message: "카드 유효기간은 6자리 숫자입니다."
                    },
                    onChange: () => {onChangeBillingForm()}
                  } )}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="pwd_2digit">
              <Form.Label>카드 비밀번호</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="앞 2자리"
                autocomplete="new-password"
                {...register("pwd_2digit",
                  {
                    required: {
                      value: true,
                      message: "카드 비밀번호 앞2자리를 입력해 주세요"
                    }, 
                    minLength: {
                      value: 2,
                      message: "카드 비밀번호는 2자리 입니다."
                    }, 
                    maxLength: {
                      value: 2,
                      message: "카드 비밀번호는 2자리 입니다."
                    },
                    onChange: () => {onChangeBillingForm()}
                  } )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Group size="lg" className="mb-3">
            <Form.Check
              required
              label= {t('TERMSOFPRIVACY').concat(" 동의")}
              {...register("chk_privacy",
                  {
                    required: {
                      value: true,
                      message: "개인정보 이용약관 동의가 필요합니다."
                    }, 
                    onChange: () => {onChangeBillingForm()}
                  } )}
            />
            <Form.Check
              required
              label= {t('TERMSOFAUTOBILLING').concat(" 동의")}
              {...register("chk_autobilling",
                  {
                    required: {
                      value: true,
                      message: "자동결제 이용약관 동의가 필요합니다."
                    }, 
                    onChange: () => {onChangeBillingForm()}
                  } )}
            />
          </Form.Group>
        </Row>
        <Row className="mx-1 my-1">
          <LoaderButton
            block
            variant="danger"
            type="submit"
            isLoading={isSubmitting}
          >
            결제
          </LoaderButton>
        </Row>
      </Form>
    </div>
    <div className="bg-light border">
      <Accordion alwaysOpen="False">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {t('TERMSOFPRIVACY')}
          </Accordion.Header>
          <Accordion.Body>
            <TermsOfPrivacy />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            {t('TERMSOFAUTOBILLING')}
          </Accordion.Header>
          <Accordion.Body>
            <TermsOfAutoBilling />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
    <div>
      <BillCompleteModal
        show={modalShow}
        onHide={hideModal}
      />
    </div>
    <div>
      <br /><br /><br />
    </div>
  </Stack>
  );
}

export default BillingForm;