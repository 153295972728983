import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import BomMasterTableCreate from "./BomMasterTableCreate";
import TableDatePickerEdit from "../../containers/TableDatePickerEdit";
import LoadingSpinner from "../../components/LoadingSpinner";

function BomMasterTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();
    const { selectedItemBalanceAppContext, setSelectedItemBalanceAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {supplyGubunState, setSupplyGubunState} = useAppContext();
    const {divisionState, setDivisionState} = useAppContext();

    const [updateState, setUpdateState] = useState(new Date());
    const [seletedBomParentItem, setSeletedBomParentItem] = useState(selectedItemBalanceAppContext&&selectedItemBalanceAppContext.id);
    const [seletedBomChildItem, setSeletedBomChildItem] = useState(1);

    useEffect(() => {
        async function onLoad() {
            
        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemBalanceAppContext.id);
        if (selectedItemBalanceAppContext) {
            setIsLoading(true);
            setTableData([]);
            axiosInstance.get('/MM/bommaster_explosion/', {
                params: { item_id: selectedItemBalanceAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setTableData(tableData => [...tableData, JSON.parse(response.data)]);
            }).catch(function (error) {
                setIsLoading(false)
            });
        }
        setIsLoading(false)
    }, [updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values);

            axiosInstance.put(`/MM/bommaster/${values.id}/`,
            {
                parentitem: values.parentitem_id,
                childitem: values.childitem_id,
                startdate: values.startdate,
                enddate: values.enddate,
                pqtym: values.pqtym,
                exdivision: values.exdivision,
                supplygubun: values.supplygubun,
                remarks: values.remarks,
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setUpdateState(new Date());
            }).catch(function (error) {
                setIsLoading(false)
            });
            setIsLoading(false)
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`해당품번을 삭제하시겠습니까? ${row.getValue('itemno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/MM/bommaster/${row.getValue('id')}/`, row.original).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setUpdateState("delete");
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false)
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'parentitem_id', //simple recommended way to define a column
                header: 'PID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'childitem_id', //simple recommended way to define a column
                header: 'CID',
                muiTableHeadCellProps: { sx: { color: 'red' } }, //optional custom props
                muiTableBodyCellProps: ({ cell }) => ({
                    onClick: () => {
                      setSeletedBomParentItem(cell.getValue());
                    },
                  }),
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'itemno', //simple recommended way to define a column
                header: '품번',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'itemspec', //simple recommended way to define a column
                header: '규격',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'startdate', //simple recommended way to define a column
                header: '시작일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'enddate', //simple recommended way to define a column
                header: '종료일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="종료일자"
                    />
                )
            },
            {
                accessorKey: 'pqtym', //simple recommended way to define a column
                header: '원단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'exdivision', //simple recommended way to define a column
                header: '이체사업부',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: divisionState.map((state) => (
                        <MenuItem key={state.key} value={state.key}>
                            {state.value}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'supplygubun', //simple recommended way to define a column
                header: '유무상',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: supplyGubunState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 100,
            },
        ],
        [getCommonEditTextFieldProps, divisionState, supplyGubunState],
    );

    return (
        <>
        <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
            <Typography variant="h6" color="white">BOM관리</Typography>
        </Box>
        {isLoading ? <LoadingSpinner /> : ""}
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 100,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                    expanded: true
                }}
                enableExpandAll={false} //hide expand all double arrow in column header
                enableExpanding
                filterFromLeafRows //apply filtering to all rows instead of just parent rows
                enableColumnOrdering
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                renderRowActions={({ row, table }) => ( 
                    !row.original.id == 0 && (<Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>)
                )}
            />
            <BomMasterTableCreate
                columns={columns}
                seletedBomParentItem={seletedBomParentItem}
                setUpdateState={setUpdateState}
            />
            
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default BomMasterTable;