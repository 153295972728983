import React, { useState, useEffect } from "react";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import ListGroup from "react-bootstrap/ListGroup";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import "./Notes.css";

export default function Notes() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      axiosInstance.get('/CO/notes/').then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setNotes(response.data);
      }).catch(function (error) {
        onError(error);
        setIsLoading(false);
      });

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  return (
    <div className="notes">
      <LinkContainer to="/notes/new">
        <ListGroup.Item action className="py-3 text-nowrap text-truncate">
          <BsPencilSquare size={17} />
          <span className="ml-2 font-weight-bold">Create a new note</span>
        </ListGroup.Item>
      </LinkContainer>
      {notes.map(({ id, subject, content, crt_date }) => (
        <LinkContainer key={id} to={`/notes/${id}`}>
          <ListGroup.Item action>
            <span className="font-weight-bold">
              {subject}
            </span>
            <br />
            <span className="text-muted">
              Created: {new Date(crt_date).toLocaleString()}
            </span>
          </ListGroup.Item>
        </LinkContainer>
      ))}
      <div>
        <br /><br /><br />
      </div>
    </div>
  );
}