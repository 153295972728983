import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    InputLabel,
    Typography
} from '@mui/material';
import * as dateFns from "date-fns";
import * as XLSX from 'xlsx';

const EXTENSIONS = ['xlsx', 'xls', 'csv']

function ItemInventoryCounting() {
    const [colDefs, setColDefs] = useState([]);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'mid', //simple recommended way to define a column
                header: 'MID'
            },
            {
                accessorKey: 'bid', //simple recommended way to define a column
                header: 'BID'
            },
            {
              accessorKey: 'itemno', //access nested data with dot notation
              header: '품번'
            },
            {
              accessorKey: 'itemname',
              header: '품명'
            },
            {
                accessorKey: 'division',
                header: '사업부'
            },
            {
              accessorKey: 'unit_stock', //normal accessorKey
              header: '단위'
            },
            {
              accessorKey: 'qty_begin',
              header: '장부재고(수량)'
            },
            {
                accessorKey: 'qty_current',
                header: '실사재고(수량)'
            }
          ],
        [],
    );

    const getExention = (file) => {
        const parts = file.name.split('.')
        const extension = parts[parts.length - 1]
        return EXTENSIONS.includes(extension) // return boolean
      }
    
      const convertToJson = (headers, data) => {
        const rows = []
        data.forEach(row => {
          let rowData = {}
          row.forEach((element, index) => {
            rowData[headers[index]] = element
          })
          rows.push(rowData)
    
        });
        //console.log(rows);
        return rows
      }
    
      const importExcel = (e) => {
        const file = e.target.files[0]
    
        const reader = new FileReader()
        reader.onload = (event) => {
          //parse data
    
          const bstr = event.target.result
          const workBook = XLSX.read(bstr, { type: "binary" })
    
          //get first sheet
          const workSheetName = workBook.SheetNames[0]
          const workSheet = workBook.Sheets[workSheetName]
          //convert to array
          const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
          //console.log(fileData[0])
          //['품번', '품명', '사업부', '자재단위', '장부재고(수량)', '실사재고(수량)']
          //const headers = fileData[0]
          const headers = ['mid','bid','itemno', 'itemname', 'division', 'unit_stock', 'qty_begin', 'qty_current']
          const headers_ = [
            {
                accessorKey: 'mid', //access nested data with dot notation
                header: 'MID'
            },
            {
                accessorKey: 'bid', //access nested data with dot notation
                header: 'BID'
            },
            {
              accessorKey: 'itemno', //access nested data with dot notation
              header: '품번'
            },
            {
              accessorKey: 'itemname',
              header: '품명'
            },
            {
                accessorKey: 'division',
                header: '사업부'
            },
            {
              accessorKey: 'unit_stock', //normal accessorKey
              header: '단위'
            },
            {
              accessorKey: 'qty_begin',
              header: '장부재고(수량)'
            },
            {
                accessorKey: 'qty_current',
                header: '실사재고(수량)'
            }
          ]
          const heads = headers_.map(head => ({ accessorKey: head.accessorKey, header: head.header }))
          setColDefs(heads)
    
          //removing header
          fileData.splice(0, 1)
    
          setData(convertToJson(headers, fileData))
        }
    
        if (file) {
          if (getExention(file)) {
            reader.readAsBinaryString(file)
          }
          else {
            alert("Invalid file input, Select Excel, CSV file")
          }
        } else {
          setData([])
          setColDefs([])
        }
      };

    const handleUploadData = () => {
        //console.log(dateFns.format(dateFns.addMonths(new Date(),-1),"yyyy.MM"));
        if (data.length == 0) {
            alert("업로드할 실사데이터가 없습니다.")
            return;
        }
        axiosInstance.post(`/MM/iteminventorycounting/`, data).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            console.log(response.data);
        }).catch(function (error) {
            setIsLoading(false);
        });
    };

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">재고조사업로드</Typography>
            </Box>
            <h8 align='center'>업로드할 실사재고대장을 선택해 주세요</h8>
            <input type="file" onChange={importExcel} />
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                }}
                columns={colDefs}
                data={data}    
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="secondary"
                            onClick={handleUploadData}
                            variant="contained"
                        >
                            재고실사대장업로드 - {dateFns.format(dateFns.addMonths(new Date(),-1),"yyyy.MM")}
                        </Button>
                    </Box>
                )}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default ItemInventoryCounting;