import React, { useState } from "react";

const html = `
<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>이용약관</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>1</span>조<span lang=EN-US>&nbsp;(</span>목적<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>본
약관은 서비스 이용자가 ㈜루크소프트<span lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;“</span>회사<span
lang=EN-US>”</span>라 한다<span lang=EN-US>)</span>가 제공하는 서비스 등 온<span lang=EN-US>/</span>오프라인상의
제반 서비스<span lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;“</span>서비스<span
lang=EN-US>”</span>라 한다<span lang=EN-US>)</span>를 이용함에 있어 회사 및 회원의 권리<span
lang=EN-US>,&nbsp;</span>의무<span lang=EN-US>,&nbsp;</span>책임사항<span lang=EN-US>,&nbsp;</span>기타
필요한 사항 등을 규정함을 목적으로 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>2</span>조<span lang=EN-US>&nbsp;(</span>용어의 정의<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;background:white'><span
style='font-size:9.0pt;line-height:107%;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;line-height:107%;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;line-height:107%;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;line-height:107%;color:#666666'>&quot;</span><span
style='font-size:9.0pt;line-height:107%;color:#666666'>회사<span lang=EN-US>&quot;</span>라
함은 ㈜ 루크소프트가 영위하는 사업 일체를 운영하는 사업자의 의미로 사용합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'> &quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원<span lang=EN-US>&quot;</span>이라
함은 서비스를 이용하기 위하여 회사에 개인정보를 제공하여 회원등록을 한 자로서 회사의 정보를 제공받고 회사가 제공하는 서비스를 이용할 수 있는
자를 말합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'> </span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>“</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>웹사이트<span lang=EN-US>”</span>라
함은 서비스를 소개하고 이용약관 및 개인정보처리방침 등을 안내하며<span lang=EN-US>,&nbsp;</span>회원가입 및 서비스를 이용할
수 있도록 회사가 운영하는 사이트를 말합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'> &quot;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>애플리케이션<span lang=EN-US>&quot;</span>이라
함은 회원가입 및 서비스를 이용할 수 있도록 회사가 운영하는 모바일 애플리케이션을 말합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑤</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp; </span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span lang=EN-US>2</span>조에서
정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래 관행에 의합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>3</span>조<span lang=EN-US>&nbsp;(</span>약관의 개정<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본 약관은 수시로 개정 가능하며 약관을 개정하고자
할 경우 회사는 개정된 약관을 적용하고자 하는 날<span lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;&quot;</span>효력
발생일<span lang=EN-US>&quot;)</span>로부터 최소<span lang=EN-US>&nbsp;7</span>일 이전에 약관이
개정된다는 사실과 개정된 내용 등을 전자우편 통보<span lang=EN-US>,&nbsp;</span>서면 통보<span
lang=EN-US>,&nbsp;</span></span><a name="_Hlk75283984"><span style='font-family:
굴림;color:#666666'>웹사이트<span lang=EN-US>/</span>애플리케이션<span lang=EN-US>&nbsp;</span></span></a><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>내 게시<span lang=EN-US>,&nbsp;</span>사업장
및 제휴 가맹점 내 게시 등의 방법 중<span lang=EN-US>&nbsp;1</span>가지 이상의 방법으로 회원에게 고지합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사가 전자우편 통보의 방법으로 본 약관이 개정된
사실 및 개정된 내용을 회원에게 고지하는 경우에<span lang=EN-US>,&nbsp;</span>회사는 회원이 회사에 기 제공한 전자우편
주소 중 가장 최근에 제공된 곳으로 통보하며<span lang=EN-US>,&nbsp;</span>회원이 최근의 정보로 변경하지 않아 발생한 손해에
대해서는 어떠한 책임도 지지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>개정된 약관<span lang=EN-US>(</span>이하<span
lang=EN-US>&nbsp;&quot;</span>개정 약관<span lang=EN-US>&quot;)</span>은 원칙적으로 그 효력 발생일로부터
장래에 향하여 유효합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를
할 수 있습니다<span lang=EN-US>.&nbsp;</span>단<span lang=EN-US>,&nbsp;</span>이의가 있음에도
불구하고 제<span lang=EN-US>1</span>항과 제<span lang=EN-US>2</span>항의 정해진 바에 따른 회사의 고지가
있은 후<span lang=EN-US>&nbsp;30</span>일 이내에 회원탈퇴를 하지 않은 회원은 개정 약관에 동의한 것으로 간주합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑤</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>이 약관에 동의하는 것은 정기적으로 회사가 운영하는
웹사이트<span lang=EN-US>/</span>애플리케이션을 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다<span
lang=EN-US>.&nbsp;</span>회사는 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원 또는 이용자의 피해에 대하여 책임을 부담하지
않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>4</span>조<span lang=EN-US>&nbsp;(</span>약관의 명시 및 효력<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 약관을 회원<span
lang=EN-US>(</span>서비스를 이용하는 자<span lang=EN-US>)</span>들이 알 수 있도록 웹사이트<span
lang=EN-US>/</span>애플리케이션에 게시합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본 약관에 명시되지 않은 사항에 대해서는 약관의
규제에 관한 법률<span lang=EN-US>,&nbsp;</span>전자문서 및 전자거래기본법<span lang=EN-US>,&nbsp;</span>전자서명법<span
lang=EN-US>,&nbsp;</span>정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang=EN-US>,&nbsp;</span>방문판매
등에 관한 법률<span lang=EN-US>,&nbsp;</span>전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령 및 회사가 정한 서비스의
세부 이용 지침 등의 규정 및 일반 상관례에 의합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>5</span>조<span lang=EN-US>&nbsp;(</span>약관 외 준칙<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 필요한 경우 특정 서비스에 관하여 적용될
사항<span lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;&quot;</span>개별약관<span
lang=EN-US>&quot;</span>이라 함<span lang=EN-US>)</span>을 정하여 이를 웹사이트<span
lang=EN-US>/</span>애플리케이션 등을 통하여 공지할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 필요한 경우 서비스 이용과 관련된 세부적인
내용<span lang=EN-US>(</span>이하<span lang=EN-US>&nbsp;&quot;</span>이용정책<span
lang=EN-US>&quot;</span>이라 함<span lang=EN-US>)</span>을 정하여 이를 웹사이트<span
lang=EN-US>/</span>애플리케이션 등을 통하여 공지할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>본 약관에 명시되지 않은 사항에 대해서는<span
lang=EN-US>&nbsp;'</span>정보통신망 이용촉진 및 정보보호 등에 관한 법률<span lang=EN-US>', '</span>전자문서
및 전자거래 기본법<span lang=EN-US>', '</span>전자상거래 등에서의 소비자보호에 관한 법률<span lang=EN-US>',
'</span>약관의 규제에 관한 법률<span lang=EN-US>', '</span>전자서명법<span lang=EN-US>',&nbsp;</span>등의
관계 법령 및<span lang=EN-US>&nbsp;'</span>회사<span lang=EN-US>'</span>가 정한 서비스의 세부 이용
지침 등의 규정에 의합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>6</span>조<span lang=EN-US>&nbsp;(</span>회원가입 및 계약의 성립<span
lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 되고자 하는 자는 회사가 정한 회원가입 절차에
의해 본 이용약관과<span lang=EN-US>&nbsp;‘</span>개인정보 처리방침<span lang=EN-US>’, ‘</span>자동차
대여 약관<span lang=EN-US>’, '</span>위치기반서비스 이용약관<span lang=EN-US>'</span>에 동의함으로써 회원가입을
신청합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 제<span lang=EN-US>1</span>항과
같은 회원가입 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다<span lang=EN-US>.&nbsp;</span>다만<span
lang=EN-US>,&nbsp;</span>다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 가입계약을 해지할 수 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>1.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>가입신청자가 제<span lang=EN-US>7</span>조 제<span lang=EN-US>2</span>항에 의하여
이전에 회원 자격을 상실한 적이 있는 경우<span lang=EN-US>(</span>다만 회원자격 상실 후 회사의 회원 재가입 승낙을 얻은 경우에는
예외로 함<span lang=EN-US>)</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>2.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>실명이 아니거나 타인의 명의를 이용한 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>3.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>등록 내용에 허위<span lang=EN-US>,&nbsp;</span>기재누락<span lang=EN-US>,&nbsp;</span>오기가
있는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>4.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>만<span lang=EN-US>&nbsp;26</span>세 미만인 자<span lang=EN-US>,&nbsp;</span>운전면허
취득<span lang=EN-US>1</span>년 미만 인자<span lang=EN-US>&nbsp;(</span>일부 서비스는<span
lang=EN-US>&nbsp;21</span>세 미만인 자<span lang=EN-US>)</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>5.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>요금 지불 능력이나 의사가 없다고 판단되거나 기타 회원으로 등록하는 것이 서비스의 원활한 운영을 저해하거나<span
lang=EN-US>,&nbsp;</span>기술상 현저히 지장이 있다고 판단되는 경우<span lang=EN-US>.&nbsp;</span>회사는
본호에 해당하는지를 판단하기 위해 회원이 되고자 하는 자의 동의를 얻어 개인 정보나 신용 정보를 수집<span lang=EN-US>,&nbsp;</span>이용
또는 신용 조회 회사 등에 제공하거나 제공받을 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원가입계약의 성립시기는 회사가 가입 완료를 신청
절차상에서 표시한 시점으로 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-family:굴림;color:#666666'><br>
<br>
</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>7</span>조<span lang=EN-US>&nbsp;(</span>회원의<span lang=EN-US>&nbsp;ID&nbsp;</span>및
비밀번호에 대한 책임과 의무<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>계정 정보<span lang=EN-US>(ID&nbsp;</span>및
비밀번호<span lang=EN-US>)</span>에 관한 관리 책임은 회원에게 있으며<span lang=EN-US>,&nbsp;</span>회원은
자신의 계정 정보를 제<span lang=EN-US>3</span>자에게 제공하지 아니하고<span lang=EN-US>&nbsp;3</span>개월마다
비밀번호 변경하여 관리의 의무를 다 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 자신의 계정 정보를 도난당하거나 제<span
lang=EN-US>3</span>자가 이용하고 있음을 인지한 경우<span lang=EN-US>,&nbsp;</span>즉시 회사에 알려야 하며
별도의 안내가 있는 경우 이에 따라야 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원이 본 조항의 주의사항을 소홀히 하여
발생한 회원의 어떠한 불이익에 대하여도 책임을 지지 아니합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-family:굴림;color:#666666'><br>
<br>
</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>8</span>조<span lang=EN-US>&nbsp;(</span>회원탈퇴 및 자격상실<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 언제든지 서면<span
lang=EN-US>,&nbsp;</span>전자우편<span lang=EN-US>,&nbsp;</span>전화<span lang=EN-US>,&nbsp;</span>기타
회사가 정하는 방법으로 회원탈퇴를 요청할 수 있으며<span lang=EN-US>,&nbsp;</span>회사는 회원의 요청에 따라 조속히 회원탈퇴를
처리합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 다음 각 호의 어느 하나에 해당하는 경우<span
lang=EN-US>,&nbsp;</span>회사는 회원자격을 상실시킬 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>1.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>가입 신청 시에 허위 내용을 등록한 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>2.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>서비스의 이용과 관련하여<span lang=EN-US>,&nbsp;</span>회원이 부담하는 채무 또는 책임을 기일
내에 이행하지 않는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>3.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>4.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>본 약관 및 관계법령에 위반되거나 공서양속에 반하는 행위를 하는 등 회원자격을 유지시키는 것이 부적절하다고 판단되는
경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 제<span lang=EN-US>2</span>항에
따른 서비스 이용정지 기타 서비스 이용과 관련된 이용 제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있으며<span
lang=EN-US>,&nbsp;</span>회사는 회원의 이의신청이 정당하다고 판단되는 경우 즉시 서비스 이용을 재개합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 사망한 경우 회원 사망 일에 회원자격이 상실됩니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑤</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원탈퇴 시<span lang=EN-US>&nbsp;3</span>개월간
회원 재가입이 제한됩니다<span lang=EN-US>.&nbsp;</span>단<span lang=EN-US>,&nbsp;</span>회사에서
허가한 경우에는 재가입이 가능합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑥</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사가 무상으로 부여한 보너스 포인트는 회원탈퇴
시 환불하지 않습니다<span lang=EN-US>(</span>회원가입 시에 회사가 제공한 무상 사용시간은 더 이상 사용할 수 없습니다<span
lang=EN-US>).&nbsp;</span>제<span lang=EN-US>5</span>항에 따라 재가입한 경우에도 회원탈퇴 시 소멸한 기존
보너스 포인트는 다시 생성되지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-family:굴림;color:#666666'><br>
<br>
</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>9</span>조<span lang=EN-US>&nbsp;(</span>회원의 서비스 이용 제한<span
lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 본 약관 제<span
lang=EN-US>10</span>조를 포함하여 회원이 본 약관 및 개별 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우<span
lang=EN-US>,&nbsp;</span>경고<span lang=EN-US>,&nbsp;</span>일시정지 및 영구정지 등으로 서비스 이용을
제한할 수 있습니다<span lang=EN-US>.&nbsp;</span>회원의 자격 재심사의 과정은 해당 회원의 서비스 이용 행태 및 이용 내역을
토대로 진행되며<span lang=EN-US>,&nbsp;</span>회사의 내부 심사 결과에 따라 서비스 이용 자격 제한 및 해제가 이뤄질 수
있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원의 서비스 이용의 제한 기간 동안 소멸되거나
미지급된 포인트<span lang=EN-US>,&nbsp;</span>쿠폰 등의 기타 혜택은 회사에서 책임지지 않습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 본 조에 따라 서비스 이용을 제한하거나 계약을
해지하는 경우에는 본 약관 제<span lang=EN-US>11</span>조에 의거하여 통지합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:18.0pt;text-align:left;text-indent:-18.0pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:7.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 본 조에 따른 이용 제한 조치에 대해 회사가
정한 절차에 따라 이의 신청을 할 수 있으며<span lang=EN-US>,&nbsp;</span>회사는 회원의 이의 신청이 정당하다고 판단되는
경우 즉시 회원 자격을 재개합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<div align=center>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse'>
 <tr style='height:12.8pt'>
  <td width=137 style='width:102.4pt;border:solid windowtext 1.0pt;border-left:
  none;background:#E5E5E5;padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>항목</span></p>
  </td>
  <td width=318 style='width:238.6pt;border:solid windowtext 1.0pt;border-left:
  none;background:#E5E5E5;padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>내용</span></p>
  </td>
  <td width=147 style='width:110.3pt;border-top:solid windowtext 1.0pt;
  border-left:none;border-bottom:solid windowtext 1.0pt;border-right:none;
  background:#E5E5E5;padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>조치사항</span></p>
  </td>
 </tr>
 <tr style='height:12.8pt'>
  <td width=137 style='width:102.4pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>회원
  정보 불일치</span></p>
  </td>
  <td width=318 style='width:238.6pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>휴대번호<span
  lang=EN-US>,&nbsp;</span>개인정보를 포함한 기본 회원정보 불일치</span></p>
  </td>
  <td width=147 style='width:110.3pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>정상
  정보 반영 시점까지 일시정지</span></p>
  </td>
 </tr>
 <tr style='height:12.8pt'>
  <td width=137 rowspan=2 style='width:102.4pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>이용자격
  부적합</span></p>
  </td>
  <td width=318 style='width:238.6pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>명의도용시</span></p>
  </td>
  <td width=147 style='width:110.3pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:12.8pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>영구정지</span></p>
  </td>
 </tr>
 <tr style='height:39.0pt'>
  <td width=318 style='width:238.6pt;border-top:none;border-left:none;
  border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:39.0pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>법
  행위 적발 및 대외 기관을 통해 수사 공문이 수신된 경우</span></p>
  </td>
  <td width=147 style='width:110.3pt;border:none;border-bottom:solid windowtext 1.0pt;
  padding:1.4pt 5.1pt 1.4pt 5.1pt;height:39.0pt'>
  <p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
  line-height:normal;text-autospace:ideograph-numeric ideograph-other;
  word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:gray'>회원
  자격 재심사 또는 영구정지</span></p>
  </td>
 </tr>
</table>

</div>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>10</span>조<span lang=EN-US>&nbsp;(</span>회원에 대한 통지<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원에 대한 통지를 하는 경우<span
lang=EN-US>,&nbsp;</span>회사는 본 약관에 별도의 규정이 없는 한<span lang=EN-US>,&nbsp;</span>회원이
회사에 등록한 주소 또는 전화번호로 전자우편 통보<span lang=EN-US>,&nbsp;</span>서신발송<span lang=EN-US>,&nbsp;</span>유선
통보 및 기타의 방법으로 할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>불특정다수 회원에 대한 통지를 하는 경우<span
lang=EN-US>,&nbsp;</span>회사는<span lang=EN-US>&nbsp;1</span>주일 이상 웹사이트<span
lang=EN-US>/</span>애플리케이션에 게시함으로써 개별 통지로 갈음할 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>11</span>조<span lang=EN-US>&nbsp;(</span>회원의 의무<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 다음 각 호에 해당하는 행위를 하여서는 안됩니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>1.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>서비스 이용 관련 제반 신청 또는 변경 행위시 허위내용 기재행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>2.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>타인의 정보를 도용하는 행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>3.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사가 게시한 각종 정보의 무단 변경<span lang=EN-US>,&nbsp;</span>삭제 등 훼손행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>4.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사가 허용한 정보 이외의 다른 정보<span lang=EN-US>(</span>컴퓨터 프로그램 및 광고 등<span
lang=EN-US>)</span>를 송신하거나 게시하는 행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>5.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사 기타 제<span lang=EN-US>3</span>자의 저작권 등 지적재산권에 대한 침해행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>6.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사 기타 제<span lang=EN-US>3</span>자의 명예를 손상시키거나 업무를 방해하는 행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>7.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>외설 또는 폭력적인 메시지<span lang=EN-US>,&nbsp;</span>화상<span lang=EN-US>,&nbsp;</span>음성
기타 공서양속에 반하는 정보를 회사 웹사이트에 공개 또는 게시하는 행위</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 관련 법령<span lang=EN-US>,&nbsp;</span>본
약관의 규정<span lang=EN-US>,&nbsp;</span>서비스 관련 이용안내 및 주의사항<span lang=EN-US>,&nbsp;</span>회사가
통지한 사항 등을 준수하여야 하며<span lang=EN-US>,&nbsp;</span>기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>13</span>조<span lang=EN-US>&nbsp;(</span>회사의 의무<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 관련법과 이 약관이 금지하거나 미풍양속에
반하는 행위를 하지 않으며<span lang=EN-US>,&nbsp;</span>계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원이 안전하게 서비스를 이용할 수 있도록
개인정보<span lang=EN-US>(</span>신용정보 포함<span lang=EN-US>)</span>보호를 위해 보안시스템을 갖추어야
하며 개인정보취급방침을 공시하고 준수합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 서비스이용과 관련하여 회원으로부터 제기된
의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다<span lang=EN-US>.&nbsp;</span>회원이 제기한 의견이나
불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>14</span>조<span lang=EN-US>&nbsp;(</span>서비스의 변경 및 중단<span
lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 사업종목의 전환<span
lang=EN-US>,&nbsp;</span>업체 간의 통합<span lang=EN-US>,&nbsp;</span>사업폐지 등 기타 회사의 판단에
의해 불가피한 영업상의 이유가 있다고 인정되는 등의 경우 제공하는 서비스의 내용을 변경할 수 있습니다<span lang=EN-US>.&nbsp;</span>이
경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의 서비스 내용을 게시한 곳에 그 제공일자 이전<span lang=EN-US>&nbsp;30</span>일
전부터 공지합니다<span lang=EN-US>.&nbsp;</span>단<span lang=EN-US>,&nbsp;</span>변동내용을 구체적으로
공지하기가 불가능한 경우에는<span lang=EN-US>&nbsp;30</span>일 전에 그 취지 및 공지가 불가능한 변동사유를 현재의 서비스를
게시한 곳에 공지합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 다음 각 호의 어느 하나에 해당하는 사유가
발생한 경우 제<span lang=EN-US>9</span>조에 명시된 서비스의 일부 또는 전부를 중단할 수 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>1.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>천재지변<span lang=EN-US>,&nbsp;</span>전쟁<span lang=EN-US>,&nbsp;</span>폭동<span
lang=EN-US>,&nbsp;</span>화재<span lang=EN-US>,&nbsp;</span>파업 등 쟁의행위<span
lang=EN-US>,&nbsp;</span>정부기관의 통제 기타 회사의 합리적인 노력으로 제어할 수 없는 사유가 발생하거나 발생할 우려가 있는
경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>2.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>기간통신사업자로부터 전기통신서비스가 제공되지 않은 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>3.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>서비스 시스템을 포함한 정보통신설비의 보수점검<span lang=EN-US>,&nbsp;</span>교체 또는 고장<span
lang=EN-US>,&nbsp;</span>통신의 두절 등의 사유가 발생한 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>4.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>서비스가 제<span lang=EN-US>3</span>자와의 제휴를 통하여 제공되는 경우에 당해 제휴사업자의 사정에
따라 변경되거나 중지되는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:33.9pt;text-align:left;text-indent:-12.7pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>5.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>기타 서비스의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span lang=EN-US>1</span>항
또는 제<span lang=EN-US>2</span>항의 사유로 서비스 내용이 변경 또는 중단되는 경우<span lang=EN-US>,&nbsp;</span>회사는
그로 인해 회원이 입은 손해에 대하여 고의 또는 중과실이 없는 한 배상하지 아니합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>15</span>조<span lang=EN-US>&nbsp;(</span>분실물 처리<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사 내에 분실물보관소를 운영하며 발견된 물품의
인계를 위한 제반 조치를 취합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>분실물 접수 및 인수인계사항은 분실물 처리대장에
기록<span lang=EN-US>,&nbsp;</span>유지합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>입수된 물품을 소유자가 찾아가지 않을 시 관할 경찰서에
신고 및 인계합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>기타 분실물 처리에 관한 제반 사항은 유실물법 및
관련 법령 등에 따릅니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>16</span>조<span lang=EN-US>&nbsp;(</span>저작권의 귀속 및 이용 제한<span
lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사가 작성한 저작물에 대한 저작권 기타 지적재산권은
회사에 귀속합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원은 서비스를 이용함으로써 얻은 정보를 회사의
사전 승낙 없이 복제<span lang=EN-US>,&nbsp;</span>송신<span lang=EN-US>,&nbsp;</span>출판<span
lang=EN-US>,&nbsp;</span>배포<span lang=EN-US>,&nbsp;</span>방송 기타 방법에 의하여 영리 목적으로
이용하거나 제<span lang=EN-US>3</span>자가 이용하도록 하여서는 안됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>17</span>조<span lang=EN-US>&nbsp;(</span>게시물의 저작권<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 서비스 내에 게시한 게시물의 저작권은 해당
게시물의 저작자에게 귀속됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>게시물은 검색결과 내지 회사의 서비스 및 관련 프로모션
등에 노출될 수 있으며<span lang=EN-US>,&nbsp;</span>해당 노출을 위해 필요한 범위 내에서는 일부 수정<span
lang=EN-US>,&nbsp;</span>복제<span lang=EN-US>,&nbsp;</span>편집되어 게시될 수 있습니다<span
lang=EN-US>.&nbsp;</span>이 경우<span lang=EN-US>,&nbsp;</span>회사는 저작권법 규정을 준수하며<span
lang=EN-US>,&nbsp;</span>회원은 언제든지 고객센터 또는 각 서비스 내 관리기능을 통해 해당 게시물 등에 대해 삭제<span
lang=EN-US>,&nbsp;</span>검색결과 제외<span lang=EN-US>,&nbsp;</span>비공개 등의 조치를 취할 수 있습니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 제<span lang=EN-US>2</span>항
이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화<span lang=EN-US>,&nbsp;</span>팩스<span
lang=EN-US>,&nbsp;</span>전자우편 등을 통해 사전에 회원의 동의를 얻습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>18</span>조<span lang=EN-US>&nbsp;(</span>공개게시물의 삭제<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원의 공개 게시물의 내용이 다음 각 항목에 해당되는
경우 회사는 회원에게 사전 통지 없이 공개 게시물을 삭제하거나<span lang=EN-US>,&nbsp;</span>비공개 설정할 수 있고 해당
회원의 이용 제한<span lang=EN-US>,&nbsp;</span>자격 상실을 시킬 수 있습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>1.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사<span lang=EN-US>,&nbsp;</span>다른 회원 또는 제<span lang=EN-US>&nbsp;3</span>자를
비방하거나 중상 모략으로 명예를 손상시키는 내용인 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>2.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>공서양속에 위반하는 내용의 정보<span lang=EN-US>,&nbsp;</span>문장<span
lang=EN-US>,&nbsp;</span>도형 등의 유포에 해당하는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>3.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>범죄행위와 관련이 있다고 판단되는 내용인 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>4. '</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>회사<span lang=EN-US>'</span>의 저작권<span lang=EN-US>,&nbsp;</span>제<span
lang=EN-US>&nbsp;3</span>자의 저작권 등 기타 권리를 침해하는 내용인 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>5.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>불필요하거나 승인되지 않은 광고<span lang=EN-US>,&nbsp;</span>판촉물을 게재하는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:39.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>6.&nbsp;</span><span style='font-size:9.0pt;font-family:굴림;
color:#666666'>동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>19</span>조<span lang=EN-US>&nbsp;(</span>분쟁해결<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 서비스 이용에 대한 안내<span
lang=EN-US>, '</span>회원<span lang=EN-US>'</span>이 제기하는 의견이나 불만을 반영하고<span
lang=EN-US>,&nbsp;</span>피해 보상을 처리하기 위하여 온라인 상담 채널 및 유선 고객센터를 상시 운영합니다<span
lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 서비스 이용과 관련하여 회원으로부터 제기된
의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다<span lang=EN-US>.&nbsp;</span>회원이 제기한 의견이나
불만사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 회원에게 처리과정 및 결과를 전달합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>20</span>조<span lang=EN-US>&nbsp;(</span>책임제한<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 천재지변 기타 이에 준하는 불가항력으로 인하여
서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원의 귀책사유로 인한 서비스 이용에 장애가
생긴 경우 책임을 지지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>③</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원이 서비스를 이용하여 기대하는 손익이나
서비스를 통하여 얻은 자료로 인한 손해에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>④</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원이 서비스에 게재한 정보<span
lang=EN-US>,&nbsp;</span>자료<span lang=EN-US>,&nbsp;</span>사실의 신뢰도<span
lang=EN-US>,&nbsp;</span>정확성에 대하여 책임을 지지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑤</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사는 회원간 또는 회원과 제<span
lang=EN-US>3</span>자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 지지 않습니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>⑥</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회원이 서비스를 이용함에 있어 행한 불법행위나 본
약관 위반행위로 인하여 회사가 당해 회원 이외의 제<span lang=EN-US>3</span>자로부터 손해배상 청구 또는 소송을 비롯한 각종
이의제기를 받는 경우<span lang=EN-US>,&nbsp;</span>당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며<span
lang=EN-US>,&nbsp;</span>그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span style='font-size:9.0pt;font-family:굴림;color:#666666'>제<span
lang=EN-US>21</span>조<span lang=EN-US>&nbsp;(</span>준거법 및 합의관할<span lang=EN-US>)</span></span></b></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>①</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사와 회원 간 제기된 소송은 대한민국법을 준거법으로
합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>②</span><span
lang=EN-US style='font-size:9.0pt;font-family:굴림;color:#666666'>&nbsp;&nbsp;</span><span
style='font-size:9.0pt;font-family:굴림;color:#666666'>회사와 회원간 발생한 분쟁에 관한 소송은 민사소송법상의
관할법원에 제소합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal align=left style='margin-bottom:0cm;text-align:left;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US style='font-size:9.0pt;font-family:굴림;
color:#666666'>&nbsp;</span></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><a name="_Hlk75353178"><b><span style='font-size:9.0pt;
font-family:굴림;color:#666666'>부칙</span></b></a></p>

<p class=MsoNormal align=left style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:19.15pt;text-align:left;text-indent:-19.15pt;
line-height:normal;background:white;text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span style='font-size:9.0pt;font-family:굴림;color:#666666'>본
약관은<span lang=EN-US>&nbsp;2022</span>년<span lang=EN-US>&nbsp;1</span>월<span
lang=EN-US>&nbsp;5</span>일부터 시행합니다<span lang=EN-US>.</span></span></p>

<p class=MsoNormal><span lang=EN-US style='font-family:굴림'>&nbsp;</span></p>

`;

function TermsOfPersonal() {
    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
}

export default TermsOfPersonal;