import React, { useRef, useState } from "react";
import { Container, Row, Figure } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import axiosInstance from "../../axiosApi";
import { IconContext } from "react-icons";
import { FaCloudDownloadAlt, FaCamera } from "react-icons/fa";
import TableDateTimeCommon from "../TableDateTimeCommon";
import moment from "moment";
import 'moment/locale/ko';

export default function ReceiptMasterNew() {
    const [source, setSource] = useState("");
    const file = useRef(null);
    const history = useHistory();
    const [content, setContent] = useState("결제상세내역입니다");
    const [isLoading, setIsLoading] = useState(false);
    const [fileNames, setFileNames] = useState([]);
    const [subject, setSubject] = useState("");
    const [receiptdate, setReceiptdate] = useState(new Date());
    const [payamount, setPayamount] = useState(0);
    const [receiptinfo, setReceiptinfo] = useState({"default":"default"});

    function validateForm() {
        return subject.length >0 && payamount > 0 && content.length > 0;
    }

    async function handleFileChange(event) {
        setIsLoading(true);
        file.current = event.target.files;
        console.log(file.current);
        // Loop through files
        setFileNames([]);
        const file_first = file.current[0];
        const newUrl = URL.createObjectURL(file_first);
        setSource(newUrl);

        for (const item of file.current) {
            let formData = new FormData();
            formData.append("file_name", item);

            axiosInstance.post('/fileupload/', formData).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                //console.log(response.data);
                setFileNames(fileNames => [...fileNames, response.data]);

                /*
                axiosInstance.get(`/fileupload/fileinfo/${response.data.id}/`).then(function (response) {
                    let json_obj;
                    json_obj = JSON.parse(response.data);
                    console.log(json_obj);
                    setSubject(json_obj.vendorname);
                    setPayamount(json_obj.payamount);
                    setReceiptinfo(json_obj);
                    setContent(json_obj.vendorname);
                }).catch(function (error) {
                    onError(error);
                });
                */
            }).catch(function (error) {
                //console.log(error);
                setIsLoading(false);
            });

        }
        setIsLoading(false);
    }

    function createNote(fileArray) {
        console.log(fileArray);
        axiosInstance.post('/FI/receiptmaster/', {
            subject: subject,
            payamount: payamount,
            receiptdate: moment(receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
            receiptinfo: receiptinfo,
            content: content,
            filemodels: fileArray
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            history.push("/receiptmaster");
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
                1000000} MB.`
            );
            return;
        }

        setIsLoading(true);

        if (fileNames) {

            var fileArray = [];
            fileNames.map((data, index) => {
                var fileid = {};
                fileid.id = data.id
                fileArray.push({ ...fileid });
            })
            // arraylist 생성 필요 "filemodels": [{"id": "2"},{"id": "3"},{"id": "4"}]
            // axios 는 async 함수로 배열처리시에 지연이 발생소지가 있어 arraylist생성이 바로 안됨.
            // 따라서 파일선택시에 업로드 처리하고 생성/수정 버튼 클릭시에 노트에 파일 연결작업을 진행함.
            if (fileArray) {
                createNote(fileArray);
            }
        }
        else {
            axiosInstance.post('/FI/receiptmaster/', {
                subject: subject,
                payamount: payamount,
                receiptdate: moment(receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
                receiptinfo: { "default": "default" },
                content: content,
                filemodels: null
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                history.push("/receiptmaster");
            }).catch(function (error) {
                //console.log(error);

                setIsLoading(false);
            });
        }
    }

    function onEditorChange(evt) {
        setContent(evt.editor.getData());
    }

    function onChangeReceiptdate(event) {
        setReceiptdate(event);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="subject">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> Capture your Receipt</span></Form.Label>
                    <Container>
                        <Row xs={12}>
                            {source &&
                                <Figure>
                                    <Figure.Image src={source} width={300} height={300} />
                                </Figure>}
                            <input
                                accept="image/*"
                                id="icon-button-file"
                                style={{ display: "none" }}
                                type="file"
                                capture="environment"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="icon-button-file">
                                <IconContext.Provider
                                    value={{ style: { color: '#A4BEF3', fontSize: '30px' } }}
                                >
                                    <div>
                                        <FaCamera />
                                        {" "}To Click
                                    </div>
                                </IconContext.Provider>
                            </label>
                        </Row>
                    </Container>
                </Form.Group>
                <Form.Group controlId="subject">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> 제목</span></Form.Label>
                    <Form.Control
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        isValid={subject.length > 0}
                        isInvalid={subject.length === 0}
                    />
                    <Form.Control.Feedback>Looks good</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">제목을 입력해주세요</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="payamount">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> 결제금액</span></Form.Label>
                    <Form.Control
                        type="number"
                        value={payamount}
                        onChange={(e) => setPayamount(e.target.value)}
                        isValid={payamount > 0}
                        isInvalid={payamount === 0}
                    />
                    <Form.Control.Feedback type="invalid">결제금액을 입력해주세요</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="receiptdate">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> 결제일자</span></Form.Label>
                    {(receiptdate.length != 0) && (
                        <TableDateTimeCommon
                            wrapperClassName="datepicker"
                            selected={receiptdate}
                            onChange={onChangeReceiptdate}
                        />
                    )}
                </Form.Group>
                {/*
                <Form.Group controlId="content">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
                    <CKEditor
                        initData={content}
                        onChange={onEditorChange}
                    />
                </Form.Group>
                <hr />
                <Form.Group controlId="file">
                    <Form.Label><span><i class="fas fa-angle-double-right"></i> Attachment</span></Form.Label>
                    <Form.Control onChange={handleFileChange} type="file" multiple />
                    {fileNames.map((data, index) => {
                        return (<><Form.Label>{data.id}{"   "}{data.file_origin_name}</Form.Label><br /></>)
                    })}
                </Form.Group>
                */}
                <LoaderButton
                    block
                    type="submit"
                    size="lg"
                    variant="primary"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Create
                </LoaderButton>
            </Form>
            <div>
                <br /><br /><br />
            </div>
        </div>
    );
}