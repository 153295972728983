// Django Rest Api connetion Utility

import axios from 'axios'
import config from "./config";
import { onError } from "./libs/errorLib";

const baseURL = `${config.DJANGO_URL}`

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? "Bearer " + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

// response interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {  
        try {
            const originalRequest = error.config;
            console.log(error.response);
            if ( error.response?.status === 401 && (!originalRequest?._retry) ) {
                //onError("Raxi App 접속이 시간제한으로 종료되었습니다.");
                originalRequest._retry = true;
                localStorage.clear();
                window.location.href = "/";
                // console.log(originalRequest);
                // const refresh_token = localStorage.getItem('refresh_token');
                // return axiosInstance
                //     .post(`${config.DJANGO_URL}/accounts/token/refresh/`, {refresh: refresh_token})
                //     .then((response) => {

                //         localStorage.setItem('access_token', response.data.access);
                //         localStorage.setItem('refresh_token', response.data.refresh);

                //         axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                //         originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                //         return axiosInstance(originalRequest);
                //     })
                //     .catch(err => {
                //         console.log(err)
                //     });
            } else {
                if( error.response?.status == 500 ) {
                    onError("서버 내부적인 오류입니다. 관리자에게 문의바랍니다.");
                } else if( error.response?.status == 400 ) {
                    onError(error.response.data);
                } else if( error.response?.status == 412 ) {
                    onError(error.response.data);
                } else if( error.response?.status == 401 ) {
                    onError("접속가능시간이 종료되었습니다. 다시 로그인해 주세요.");
                } else {
                    onError(error);
                }
            }
        } catch (err) {
            localStorage.clear();
            window.location.href = "/";
        }     
    }
)

export default axiosInstance