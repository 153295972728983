import {
    InputLabel
} from '@mui/material';
import { Controller } from "react-hook-form";
import React from "react";
import TableDatePicker from "../containers/TableDatePicker";

export const ControlDatePicker = ({ name, control, rules, label, type }) => {
    return <>
        <InputLabel sx={{color: "black"}}>{label}</InputLabel>
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value, ref } }) => (
                <TableDatePicker
                    wrapperClassName="datepicker"
                    selected={value}
                    onChange={onChange} 
                />
            )}
        />
    </>
};