import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import * as dateFns from "date-fns";

function ItemInventoryCountingFind() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {itemClassState, setItemClassState} = useAppContext();
    const {itemSourceState, setItemSourceState} = useAppContext();
    const {divisionState, setDivisionState} = useAppContext();
    const {unitState, setUnitState} = useAppContext();

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemAppContext.id);
        setTableData([]);
        axiosInstance.get('/MM/itembalance/').then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            console.log(response.data);
            response.data.map((data_row) => {
                let itemData = {};

                itemData['mid'] = data_row.itemmaster.id
                itemData['bid'] = data_row.id
                itemData['itemno'] = data_row.itemmaster.itemno
                itemData['itemname'] = data_row.itemmaster.itemname
                itemData['division'] = data_row.division
                itemData['unit_stock'] = data_row.unit_stock
                itemData['qty_begin'] = data_row.qty_begin
                itemData['qty_current'] = data_row.qty_current
                setTableData(tableData => [...tableData, itemData])
            });

            //setTableData(response.data);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'mid', //simple recommended way to define a column
                header: 'MID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'bid', //simple recommended way to define a column
                header: 'BID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'itemno', //simple recommended way to define a column
                header: '품번',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
            },
            {
                accessorKey: 'itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
            },
            {
                accessorKey: 'division', //simple recommended way to define a column
                header: '사업부',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: divisionState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'unit_stock', //simple recommended way to define a column
                header: '자재단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: unitState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_begin', //simple recommended way to define a column
                header: '장부재고(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_current', //simple recommended way to define a column
                header: '실사재고(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span></span>, //optional custom cell render
            }
        ],
        [divisionState, unitState],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
      };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">재고실사대장</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                }}
                enableColumnOrdering
                tableInstanceRef={tableInstanceRef}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            CSV - {dateFns.format(dateFns.addMonths(new Date(),-1),"yyyy.MM")}
                        </Button>
                    </Box>
                )}
            />
        </>
    );
}

export default ItemInventoryCountingFind;