import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Alert,
    Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlInputText } from "../../components/ControlInputText";
import { ControlCheckbox } from "../../components/ControlCheckbox";
import { ControlDatePicker } from "../../components/ControlDatePicker";
import { IconContext } from "react-icons";
import { FaCloudDownloadAlt, FaCamera } from "react-icons/fa";
import * as dateFns from "date-fns";
import config from "../../config";
import moment from "moment";
import 'moment/locale/ko';

//example of creating a mui dialog modal for creating new rows
const ReceiptMasterTableCreate = ({ columns, setUpdateState }) => {
    const { register, handleSubmit, formState, getValues, setValue, control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const { isSubmitting } = formState;
    const { t } = useTranslation();
    const [source, setSource] = useState("");
    const file = useRef(null);
    const [content, setContent] = useState("결제상세내역입니다");
    const [fileNames, setFileNames] = useState([]);
    const [subject, setSubject] = useState("");
    const [receiptdate, setReceiptdate] = useState(new Date());
    const [payamount, setPayamount] = useState(0);
    const [receiptinfo, setReceiptinfo] = useState({"default":"default"});

    const onChangeBillingForm = () => {
        const regex = /^[0-9\b -]{0,13}$/;
        //var f_data = getValues()
        //columns.map((column) => console.log(column.accessorKey));
        //console.log(f_data);
        // if (f_data.startdate){
        //     console.log(dateFns.format(f_data.startdate,"yyyy-MM-dd"));
        //     //setValue("startdate", dateFns.format(f_data.startdate,"yyyy-MM-dd"))
        // }
        // if (f_data.enddate){
        //     console.log(dateFns.format(f_data.enddate,"yyyy-MM-dd"));
        //     //setValue("enddate", dateFns.format(f_data.enddate,"yyyy-MM-dd"))
        // }
        // if (f_data.expiry){
        //     setValue("expiry", f_data.expiry.replace(/-/g, '').replace(/(\d{4})(\d{2})/, '$1-$2'))
        // }
        return;
    }

    function createNote(data, fileArray) {
        //console.log(fileArray);
        axiosInstance.post('/FI/receiptmaster/', {
            subject: data.subject,
            payamount: data.payamount,
            receiptdate: moment(data.receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
            receiptinfo: {"default":"default"},
            content: data.content,
            filemodels: fileArray
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            setUpdateState(new Date());
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    const onSubmit = (data) => {
        if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
            alert(
                `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
                1000000} MB.`
            );
            return;
        }

        setIsLoading(true);
        //console.log(fileNames);
        if (fileNames.length > 0) {

            var fileArray = [];
            fileNames.map((data, index) => {
                var fileid = {};
                fileid.id = data.id
                fileArray.push({ ...fileid });
            })
            // arraylist 생성 필요 "filemodels": [{"id": "2"},{"id": "3"},{"id": "4"}]
            // axios 는 async 함수로 배열처리시에 지연이 발생소지가 있어 arraylist생성이 바로 안됨.
            // 따라서 파일선택시에 업로드 처리하고 생성/수정 버튼 클릭시에 노트에 파일 연결작업을 진행함.
            if (fileArray) {
                createNote(data, fileArray);
            }
        }
        else {
            axiosInstance.post('/FI/receiptmaster/', {
                subject: data.subject,
                payamount: data.payamount,
                receiptdate: moment(data.receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
                receiptinfo: { "default": "default" },
                content: data.content,
                filemodels: null
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setUpdateState(new Date());
            }).catch(function (error) {
                //console.log(error);

                setIsLoading(false);
            });
        }
    };

    async function handleFileChange(event) {
        setIsLoading(true);
        file.current = event.target.files;
        console.log(file.current);
        // Loop through files
        setFileNames([]);
        const file_first = file.current[0];
        const newUrl = URL.createObjectURL(file_first);
        setSource(newUrl);

        for (const item of file.current) {
            let formData = new FormData();
            formData.append("file_name", item);

            axiosInstance.post('/fileupload/', formData).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                //console.log(response.data);
                setFileNames(fileNames => [...fileNames, response.data]);

                /*
                axiosInstance.get(`/fileupload/fileinfo/${response.data.id}/`).then(function (response) {
                    let json_obj;
                    json_obj = JSON.parse(response.data);
                    console.log(json_obj);
                    setSubject(json_obj.vendorname);
                    setPayamount(json_obj.payamount);
                    setReceiptinfo(json_obj);
                    setContent(json_obj.vendorname);
                }).catch(function (error) {
                    onError(error);
                });
                */
            }).catch(function (error) {
                //console.log(error);
                setIsLoading(false);
            });

        }
        setIsLoading(false);
    }

    return (
        <>

                <Stack
                    sx={{
                        width: '100%',
                        minWidth: { xs: '260px', sm: '300px', md: '400px' },
                        gap: '1.5rem',
                        border: 2,
                        padding: 0.5,
                        marginBottom: 7
                    }}
                >
                    {(!formState.isValid && formState.isSubmitted) ?
                        <Alert severity="error"   >
                            {Object.values(formState.errors).map((e, idx) => {
                                return (<p key={idx}>{e.message}</p>)
                            })}
                        </Alert>
                        :
                        <Alert severity="success">신규영수증를 입력해 주세요</Alert>
                    }
                    <div>
                        {source &&
                        <Box
                            component="img"
                            sx={{
                            height: 300,
                            width: 300,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                            }}
                            src={source}
                        />}
                        <input
                            accept="image/*"
                            id="icon-button-file"
                            style={{ display: "none" }}
                            type="file"
                            capture="environment"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="icon-button-file">
                            <IconContext.Provider
                                value={{ style: { color: '#A4BEF3', fontSize: '30px' } }}
                            >
                                <div>
                                    <FaCamera />
                                    {" "}사진선택/촬영
                                </div>
                            </IconContext.Provider>
                        </label>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        {columns.map((column) => {
                            if (column.accessorKey == 'subject') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "제목을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue=""
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'payamount') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "결제금액을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue=""
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'receiptdate') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlDatePicker
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "결제일자를 선택해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'content') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: "내용을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                            
                            <Grid item xs={12} md={6}>
                                <DialogActions sx={{ p: '1.25rem' }}>
                                    <Button color="secondary" type="submit" variant="contained">
                                        신규영수증 등록
                                    </Button>
                                </DialogActions>
                            </Grid>

                        </Grid>
                    </form>
                </Stack>
        </> 
    );
};

export default ReceiptMasterTableCreate;