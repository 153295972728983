import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import LoginSocial from "./containers/LoginSocial";
import LoginBasic from "./containers/LoginBasic";
import SignupSocial from "./containers/SignupSocial";
import SignupBasic from "./containers/SignupBasic";
import SignupPhone from "./containers/SignupPhone";
import Settings from "./containers/Settings";
import WorkCalendar from "./containers/WorkCalendar";
import WorkCalendarNew from "./containers/WorkCalendarNew";
import WorkCalendarInfo from "./containers/WorkCalendarInfo";
import UserDetail from "./containers/UserDetail";
import PasswordResetView from "./containers/PasswordResetView";
import SignupConfirm from "./containers/SignupConfirm";
import OAuth2RedirectHandler from "./components/OAuth2RedirectHandler";
import NotFound from "./containers/NotFound";
import NoteNew from "./containers/NoteNew_ckeditor";
import NoteInfo from "./containers/NoteInfo_ckeditor";
import Notes from "./containers/Notes_ckeditor";
import ReceiptMaster from "./containers/fi/ReceiptMaster";
import ReceiptMasterNew from "./containers/fi/ReceiptMasterNew";
import ReceiptMasterInfo from "./containers/fi/ReceiptMasterInfo";
import ItemManage from "./containers/mm/ItemManage";
import BomManage from "./containers/mm/BomManage";
import CompanyManage from "./containers/mm/CompanyManage";
import PurManage from "./containers/mm/PurManage";
import DepartmentManage from "./containers/hr/DepartmentManage";
import EmployeeManage from "./containers/hr/EmployeeManage";
import SalesManage from "./containers/sd/SalesManage";
import BomStdCost from "./containers/mm/BomStdCost";
import ProductPlanMonthManage from "./containers/pp/ProductPlanMonthManage";
import MrpPlanMonthManage from "./containers/pp/MrpPlanMonthManage";
import WipCurrentManage from "./containers/pp/WipCurrentManage";
import ItemInventoryCountingManage from "./containers/mm/ItemInventoryCountingManage";
import FiManage from "./containers/fi/FiManage";
import ReceiptManage from "./containers/fi/ReceiptManage";

export default function Routes() {
  
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/loginsocial">
        <LoginSocial />
      </Route>
      <Route exact path="/loginbasic">
        <LoginBasic />
      </Route>
      <Route exact path="/signupsocial">
        <SignupSocial />
      </Route>
      <Route exact path="/signupbasic">
        <SignupBasic />
      </Route>
      <Route exact path="/signupphone">
        <SignupPhone />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/workcalendar">
        <WorkCalendar />
      </Route>
      <Route exact path="/workcalendar/new">
        <WorkCalendarNew />
      </Route>
      <Route exact path="/workcalendar/:id">
        <WorkCalendarInfo />
      </Route>
      <Route exact path="/userdetail">
        <UserDetail />
      </Route>
      <Route exact path="/accounts/kakao/callback/react">
        <OAuth2RedirectHandler />
      </Route>
      <Route exact path="/signupconfirm">
        <SignupConfirm />
      </Route>
      <Route exact path="/auth/reset/:uid/:token" component={PasswordResetView}/>
      <Route exact path="/notes/new">
        <NoteNew />
      </Route>
      <Route exact path="/notes/:id">
        <NoteInfo />
      </Route>
      <Route exact path="/notes">
        <Notes />
      </Route>
      <Route exact path="/receiptmaster/new">
        <ReceiptMasterNew />
      </Route>
      <Route exact path="/receiptmaster/:id">
        <ReceiptMasterInfo />
      </Route>
      <Route exact path="/receiptmaster">
        <ReceiptMaster />
      </Route>
      <Route exact path="/mmmanage">
        <ItemManage />
      </Route>
      <Route exact path="/ppmanage">
        <ProductPlanMonthManage />
      </Route>
      <Route exact path="/bommanage">
        <BomManage />
      </Route>
      <Route exact path="/companymanage">
        <CompanyManage />
      </Route>
      <Route exact path="/purmanage">
        <PurManage />
      </Route>
      <Route exact path="/hrmanage">
        <DepartmentManage />
      </Route>
      <Route exact path="/sdmanage">
        <SalesManage />
      </Route>
      <Route exact path="/employeemanage">
        <EmployeeManage />
      </Route>
      <Route exact path="/bomstdcost">
        <BomStdCost />
      </Route>
      <Route exact path="/mrpmanage">
        <MrpPlanMonthManage />
      </Route>
      <Route exact path="/wipcurrentmanage">
        <WipCurrentManage />
      </Route>
      <Route exact path="/iteminventorycountingmanage">
        <ItemInventoryCountingManage />
      </Route>
      <Route exact path="/fimanage">
        <FiManage />
      </Route>
      <Route exact path="/receiptmanage">
        <ReceiptManage />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}