import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

function BomStdCost() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();
    const { selectedItemBalanceAppContext, setSelectedItemBalanceAppContext } = useAppContext();
    const { selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {itemClassState, setItemClassState} = useAppContext();
    const {itemSourceState, setItemSourceState} = useAppContext();
    const {divisionState, setDivisionState} = useAppContext();
    const {unitState, setUnitState} = useAppContext();

    const tableInstanceRef = useRef(null);

    const averageMaterialCost = useMemo(
        () => tableData.reduce((acc, curr) => acc + (curr.cost_average * curr.total_amount), 0),
        [tableData],
      );

      const lastMaterialCost = useMemo(
        () => tableData.reduce((acc, curr) => acc + (curr.cost_last * curr.total_amount), 0),
        [tableData],
      );

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        if (selectedItemAppContext) {
            setTableData([]);
            axiosInstance.get('/MM/bomexp_stdcost/', {
                params: { item_id: selectedItemBalanceAppContext.id }
            }).then(function (response) {
                setTableData(JSON.parse(response.data));
            }).catch(function (error) {
                onError(error);
            });
        }
    }, [selectedItemAppContext, selectedItemBalanceUpdateAppContext]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'modelno', //simple recommended way to define a column
                header: '모델',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'level', //simple recommended way to define a column
                header: 'Lv.',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'total_amount', //simple recommended way to define a column
                header: '원단위(합계)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'cost_average', //simple recommended way to define a column
                header: '이동평균단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => (
                    <>
                      {cell.getValue()?.toLocaleString?.('ko-KR', {
                        style: 'currency',
                        currency: 'KRW',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </>
                  ),
                Footer: () => (
                    <Stack>
                      Average Mat.Cost:
                      <Box color="warning.main">
                        {averageMaterialCost?.toLocaleString?.('ko-KR', {
                          style: 'currency',
                          currency: 'KRW',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Box>
                    </Stack>
                  ),
            },
            {
                accessorKey: 'cost_last', //simple recommended way to define a column
                header: '최종입고단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => (
                    <>
                      {cell.getValue()?.toLocaleString?.('ko-KR', {
                        style: 'currency',
                        currency: 'KRW',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </>
                  ),
                Footer: () => (
                    <Stack>
                      Last Mat.Cost:
                      <Box color="warning.main">
                        {lastMaterialCost?.toLocaleString?.('ko-KR', {
                          style: 'currency',
                          currency: 'KRW',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </Box>
                    </Stack>
                  ),
            },
            {
                accessorKey: 'itemno', //simple recommended way to define a column
                header: '품번',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemspec', //simple recommended way to define a column
                header: '규격',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemclass', //simple recommended way to define a column
                header: '계정',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemsource', //simple recommended way to define a column
                header: '구입선',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'startdate', //simple recommended way to define a column
                header: '시작일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'enddate', //simple recommended way to define a column
                header: '종료일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'pqtym', //simple recommended way to define a column
                header: '원단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'bomunit', //simple recommended way to define a column
                header: 'BOM단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'invunit', //simple recommended way to define a column
                header: '자재단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'factor_conversion', //simple recommended way to define a column
                header: '변환계수',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [averageMaterialCost, lastMaterialCost],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">BOM 표준재료비</Typography>
            </Box>
            <MaterialReactTable
                columns={columns}
                data={tableData}
                enableColumnResizing
                enableGrouping
                enableStickyHeader
                enableStickyFooter
                initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    grouping: ['modelno'], //an array of columns to group by by default (can be multiple)
                    sorting: [{ id: 'modelno', desc: false }], //sort by state by default
                }}
                muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            />
        </>
    );
}

export default BomStdCost;