import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationFI() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            <Nav fill variant="pills">
                <LinkContainer to="/fimanage" className="nav-link">
                  <Nav.Link>환경관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/receiptmanage" className="nav-link">
                  <Nav.Link>영수증관리</Nav.Link>
              </LinkContainer>
            </Nav>
        </div>

    );
}

export default SubNavigationFI;