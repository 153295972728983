import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form, Button, Modal } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import config from "../config";
import DropdownSelector from "../components/DropdownSelector";
import BillingForm from "../components/BillingForm";
import moment from "moment";

function OpenBillingForm(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          결제카드 등록
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BillingForm />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function UserDetail() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [nickname, setNickname] = useState("");
  const [phone, setPhone] = useState("");
  const [route_default, setRoute_default] = useState("");
  const { user, setUser } = useAppContext();
  const [isBillingKey, setIsBillingKey] = useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  const { userHasAuthenticated } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    old_password: "",
    new_password1: "",
    new_password2: ""
  });
  const [bookDate, setBookDate] = useState('');
  const [routeOptions, setRouteOptions] = useState([]);
  const { setRouteAppContext } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { setRouteMap } = useAppContext();
  const { setLeadtimeAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const [modalShow, setModalShow] = React.useState(false);

  const { t } = useTranslation();
  
  useEffect(() => { 
    onLoad();
  }, []);
  
  async function onLoad() {
      axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setUserDetail(response.data);
        setNickname(response.data.nickname);
        setPhone(response.data.phone_number);
        setRoute_default(response.data.route_default);
        if (response.data.status === 'CARD') {
          setIsBillingKey(true);
        } else {
          setIsBillingKey(false);
        }

      }).catch(function (error) {
        onError(error);
      });

      axiosInstance.get('/CO/basicinfo/').then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        //console.log(response.data.current_datetime);
        setBookDate(response.data.current_datetime);
      }).catch(function (error) {
        onError(error);
      });
  }

  function validateForm() {
    return (
      nickname.length > 0 &&
      phone.length > 0
    );
  }
  
  function validatePasswordForm() {
    return (
      fields.old_password.length > 0 &&
      fields.new_password1.length > 0 &&
      fields.new_password1 === fields.new_password2
    );
  }
  
  async function saveUserDetail(detail) {
    axiosInstance.put(`/accounts/user/${tempUser.pk}/`, detail).then(function(response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      onError(t('PROCESS_OK'));
      return;
    }).catch(function (error) {
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveUserDetail({
        email: userDetail.email,
        nickname: nickname,
        phone_number: phone,
        is_staff: userDetail.is_staff,
        is_active: userDetail.is_active,
        user_type: userDetail.user_type === '' ? 'GUEST' : userDetail.user_type,
        status: userDetail.status,
        route_default: route_default
      });
      setIsLoading(false);
    } catch (e) {
      onError(t('PROCESS_FAIL'));
      setIsLoading(false);
    }
  }

  async function deleteUserDetail(detail) {
    return axiosInstance.put(`/accounts/user/${tempUser.pk}/`, detail).then(function(response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      return;
    }).catch(function (error) {
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      t('WITHDRAW_MSG')
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await saveUserDetail({
        email: userDetail.email,
        nickname: userDetail.nickname,
        phone_number: userDetail.phone_number,
        is_staff: userDetail.is_staff,
        is_active: 0,
        user_type: userDetail.user_type === '' ? 'GUEST' : userDetail.user_type,
        status: userDetail.status,
        route_default: userDetail.route_default
      });
      
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('access_user');
      userHasAuthenticated(false);
      setUser(null);
      history.push("/");
    } catch (e) {
      onError(t('PROCESS_FAIL'));
      setIsDeleting(false);
    }
  }
  
  async function handlePasswordChange(event) {
    event.preventDefault();

    setIsLoading(true);
    
    axiosInstance.post('/accounts/password/change/', {
                old_password: fields.authcode,
                new_password1: fields.new_password1,
                new_password2: fields.new_password2
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      onError(t('PROCESS_OK'));
      setIsLoading(false);
    }).catch(function (error) {
      onError(error);
      userHasAuthenticated(false);
      setIsLoading(false);
    });

  }

  async function handleCardRegister(event) {
    history.push("/billingform");
    //showModal();
  }

  async function handleCardDelete(event) {
    event.preventDefault();

    setIsLoading(true);
    
    axiosInstance.put(`/accounts/user/${tempUser.pk}/`, {
        email: userDetail.email,
        nickname: userDetail.nickname,
        phone_number: userDetail.phone_number,
        is_staff: userDetail.is_staff,
        is_active: userDetail.is_active,
        user_type: 'GUEST',
        status: 'NORMAL',
        route_default: userDetail.route_default
    }).then(function(response) {
        alert("정상적으로 처리되었습니다");
        setIsBillingKey(false);
        setIsLoading(false);
        onLoad();
    }).catch(function (error) {
      onError(error);
        //onError(t('PROCESS_FAIL'));
        setIsLoading(false);
    }); 

  }
  
  function onChangeRoute(event) {
    
  }

  function showModal() {
    setModalShow(true);
  }
  
  function hideModal() {
    setModalShow(false);
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border titlesubject">
        {t('USERDETAIL')}
      </div>
      <div>
          {userDetail && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={userDetail.email}
                  disabled
                  readOnly
                />
              </Form.Group>
              <Form.Group controlId="nickname">
                <Form.Label>{t('NICKNAME')}</Form.Label>
                <Form.Control
                  type="text"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>{t('PHONE_NO')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('PHONE_MSG')}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Group>
              {/*
              <Form.Group controlId="route_default">
                <Form.Label>{t('ROUTE_DEFAULT')}</Form.Label>
                <DropdownSelector
                  labelName=""
                  options={routeOptions}
                  defaultValue={route_default}
                  onChange={onChangeRoute}
                />
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label>{t('PAYMENT_INFO')}</Form.Label>
                <Form.Control
                  type="text"
                  value={userDetail.status}
                  disabled
                  readOnly
                />
              </Form.Group>
              */}
              <LoaderButton
                block
                size="lg"
                variant="primary"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                {t('SAVE')}
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                {t('WITHDRAW')}
              </LoaderButton>
              {/*
              <LoaderButton
                block
                size="lg"
                variant="primary"
                onClick={handleCardRegister}
                isLoading={isLoading}
                disabled={isBillingKey}
              >
                {t('CARDSAVE')}
              </LoaderButton>
              
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleCardDelete}
                isLoading={isDeleting}
                disabled={!isBillingKey}
              >
                {t('CARDDELETE')}
              </LoaderButton>
              */}
            </Form>
          )}
      </div>
      <div className="bg-light border titlesubject">
        {t('PWCHANGE')}
      </div>
      <div>
        <Form onSubmit={handlePasswordChange}>
          <Form.Group controlId="old_password" size="lg">
            <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.old_password}
              />
          </Form.Group>
          <Form.Group controlId="new_password1" size="lg">
            <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.new_password1}
              />
          </Form.Group>
          <Form.Group controlId="new_password2" size="lg">
            <Form.Label>New Password(Verify)</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.new_password2}
              />
          </Form.Group>
          {t('PASSWORDINFO')}
          <LoaderButton
            block
            size="lg"
            variant="primary"
            isLoading={isLoading}
            onClick={handlePasswordChange}
            disabled={!validatePasswordForm()}
          >
            {t('REQUEST_CHANGE')}
          </LoaderButton>
        </Form>
      </div>
      <div>
        <OpenBillingForm
          show={modalShow}
          onHide={hideModal}
        />
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default UserDetail;