import {
    MenuItem,
    TextField,
} from '@mui/material';
import { Controller } from "react-hook-form";
import React from "react";

export const ControlInputTextMenuItem = ({ name, control, rules, label, type, defaultValue, itemStatus }) => {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value, ref } }) => (
            <TextField
                select
                fullWidth
                variant="standard"
                type={type}
                inputProps={{
                    autoComplete: "new-password",
                }}
                label={label}
                inputRef={ref}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
            >
                {
                    itemStatus.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    ))
                }
            </TextField>
        )}
    />
};