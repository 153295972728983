import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Alert,
    Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlInputText } from "../../components/ControlInputText";
import { ControlCheckbox } from "../../components/ControlCheckbox";
import { ControlDatePicker } from "../../components/ControlDatePicker";
import ItemBalanceTableFind from "./ItemBalanceTableFind";
import TableDatePicker from "../TableDatePicker";
import DatePicker from "react-datepicker"; 
import * as dateFns from "date-fns";

//example of creating a mui dialog modal for creating new rows
const BomMasterTableCreate = ({ columns, seletedBomParentItem, setUpdateState }) => {
    const { register, handleSubmit, formState, getValues, setValue, control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const { isSubmitting } = formState;
    const [selectModalOpen, setSelectModalOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setValue("parentitem_id", seletedBomParentItem)
    }, [seletedBomParentItem]);

    const onChangeBillingForm = () => {
        const regex = /^[0-9\b -]{0,13}$/;
        //var f_data = getValues()
        //columns.map((column) => console.log(column.accessorKey));
        //console.log(f_data);
        // if (f_data.startdate){
        //     console.log(dateFns.format(f_data.startdate,"yyyy-MM-dd"));
        //     //setValue("startdate", dateFns.format(f_data.startdate,"yyyy-MM-dd"))
        // }
        // if (f_data.enddate){
        //     console.log(dateFns.format(f_data.enddate,"yyyy-MM-dd"));
        //     //setValue("enddate", dateFns.format(f_data.enddate,"yyyy-MM-dd"))
        // }
        // if (f_data.expiry){
        //     setValue("expiry", f_data.expiry.replace(/-/g, '').replace(/(\d{4})(\d{2})/, '$1-$2'))
        // }
        return;
    }

    const onSubmit = (data) => {
        //put your validation logic here
        // call axios post api
        axiosInstance.post(`/MM/bommaster/`, {
            parentitem: data.parentitem_id,
            childitem: data.childitem_id,
            startdate: dateFns.format(data.startdate,"yyyy-MM-dd"),
            enddate: dateFns.format(data.enddate,"yyyy-MM-dd"),
            pqtym: data.pqtym,
            exdivision: "",
            supplygubun: "",
            remarks: "",
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            setUpdateState(new Date());
        }).catch(function (error) {
            console.log(error);
            setIsLoading(false);
        });
    };

    return (
        <>

                <Stack
                    sx={{
                        width: '100%',
                        minWidth: { xs: '260px', sm: '300px', md: '400px' },
                        gap: '1.5rem',
                        border: 2,
                        padding: 0.5,
                    }}
                >
                    {(!formState.isValid && formState.isSubmitted) ?
                        <Alert severity="error"   >
                            {Object.values(formState.errors).map((e, idx) => {
                                return (<p key={idx}>{e.message}</p>)
                            })}
                        </Alert>
                        :
                        <Alert severity="success">BOM정보를 입력해 주세요</Alert>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        {columns.map((column) => {
                            if (column.accessorKey == 'parentitem_id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "모품번을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'childitem_id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "자품번을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'startdate') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlDatePicker
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "시작일자를 선택해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'enddate') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlDatePicker
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "종료일자를 선택해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'pqtym') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "원단위을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                            
                            <Grid item xs={12} md={6}>
                                <DialogActions sx={{ p: '1.25rem' }}>
                                    <Button color="secondary" type="submit" variant="contained">
                                        BOM 등록
                                    </Button>
                                </DialogActions>
                            </Grid>

                        </Grid>

                    </form>
                </Stack>
                <ItemBalanceTableFind
                    setValue={setValue}
                    columnName='childitem_id'
                />
        </> 
    );
};

export default BomMasterTableCreate;