import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationUser() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            
        </div>

    );
}

export default SubNavigationUser;