import React from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";
import SubNavigationManager from "./SubNavigationManager";
import SubNavigationUser from "./SubNavigationUser";
import SubNavigationMM from "./SubNavigationMM";
import SubNavigationHR from "./SubnavigationHR";
import SubNavigationSD from "./SubNavigationSD";
import SubNavigationPP from "./SubNavigationPP";
import SubNavigationFI from "./SubnavigationFi";
import { useHistory } from "react-router-dom";

function SubNavigation(props) {
  const { t } = useTranslation();
  const history = useHistory();

  function renderSwitch(param) {
    switch(param) {
      case '/settings':
        return <SubNavigationManager />;
      case '/mmmanage':
        return <SubNavigationMM />;
      case '/hrmanage':
        return <SubNavigationHR />;
      case '/fimanage':
        return <SubNavigationFI />;
      case '/sdmanage':
        return <SubNavigationSD />;
      case '/ppmanage':
        return <SubNavigationPP />;
      default:
        return <SubNavigationUser />;
    }
  }
  
  return (
    <>
      { renderSwitch(props.currentLocation) }
    </>
  );
}

export default SubNavigation;