import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

function ItemBalanceTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();
    const { selectedItemBalanceAppContext, setSelectedItemBalanceAppContext } = useAppContext();
    const { selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {itemClassState, setItemClassState} = useAppContext();
    const {itemSourceState, setItemSourceState} = useAppContext();
    const {divisionState, setDivisionState} = useAppContext();
    const {unitState, setUnitState} = useAppContext();

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemAppContext.id);
        setTableData([]);
        if (selectedItemAppContext) {
            axiosInstance.get('/MM/itembalance/', {
                params: { itemmaster_id: selectedItemAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                //console.log(response.data);
                setTableData(response.data);
                setRowSelection({ 0: true });
            }).catch(function (error) {
                setIsLoading(false);
            });
        }
    }, [selectedItemAppContext, selectedItemBalanceUpdateAppContext]);

    useEffect(() => {
        //console.info(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
        if (tableInstanceRef.current.getSelectedRowModel().rows.length > 0) {
            //console.info(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
            setSelectedItemBalanceAppContext(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
        }
    }, [rowSelection]);

    const handleCreateNewRow = (values) => {
        //console.log(values);
        //console.log(selectedItemAppContext);
        // call axios post api
        axiosInstance.post(`/MM/itembalance/`, {
            itemmaster: selectedItemAppContext.id,
            division: values.division,
            itemclass: values.itemclass,
            itemsource: values.itemsource,
            unit_stock: values.unit_stock,
            unit_purchase: values.unit_purchase,
            plan_stock: values.plan_stock,
            plan_purchase: values.plan_purchase,
            factor_conversion: values.factor_conversion ? values.factor_conversion : 1,
            warelocation: values.warelocation,
            qty_begin: values.qty_begin ? values.qty_begin : 0,
            amt_begin: values.amt_begin ? values.amt_begin : 0,
            qty_in: values.qty_in ? values.qty_in : 0,
            amt_in: values.amt_in ? values.amt_in : 0,
            qty_out: values.qty_out ? values.qty_out : 0,
            amt_out: values.amt_out ? values.amt_out : 0,
            qty_current: values.qty_current ? values.qty_current : 0,
            amt_current: values.amt_current ? values.amt_current : 0,
            cost_average: values.cost_average ? values.cost_average : 0,
            cost_last: values.cost_last ? values.cost_last : 0,
            remarks: values.remarks,
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            tableData.push(response.data);
            setTableData([...tableData]);
        }).catch(function (error) {
            setIsLoading(false);
        });
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values.id);
            axiosInstance.put(`/MM/itembalance/${values.id}/`,
                {
                    itemmaster: selectedItemAppContext,
                    division: values.division,
                    itemclass: values.itemclass,
                    itemsource: values.itemsource,
                    unit_stock: values.unit_stock,
                    unit_purchase: values.unit_purchase,
                    plan_stock: values.plan_stock,
                    plan_purchase: values.plan_purchase,
                    factor_conversion: values.factor_conversion ? values.factor_conversion : 1,
                    warelocation: values.warelocation,
                    qty_begin: values.qty_begin ? values.qty_begin : 0,
                    amt_begin: values.amt_begin ? values.amt_begin : 0,
                    qty_in: values.qty_in ? values.qty_in : 0,
                    amt_in: values.amt_in ? values.amt_in : 0,
                    qty_out: values.qty_out ? values.qty_out : 0,
                    amt_out: values.amt_out ? values.amt_out : 0,
                    qty_current: values.qty_current ? values.qty_current : 0,
                    amt_current: values.amt_current ? values.amt_current : 0,
                    cost_average: values.cost_average ? values.cost_average : 0,
                    cost_last: values.cost_last ? values.cost_last : 0,
                    remarks: values.remarks,
                }).then(function (response) {
                    if (typeof response === 'undefined') throw "에러처리루틴 완료";
                    tableData[row.index] = values;
                    setTableData([...tableData]);
                }).catch(function (error) {
                    setIsLoading(false);
                });
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`해당품목을 삭제하시겠습니까? ${row.getValue('itemmaster.itemno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/MM/itembalance/${row.getValue('id')}/`, row.original).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
            }).catch(function (error) {
                setIsLoading(false);
            });
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'factor_conversion'
                                ? validateFactor_conversion(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'division', //simple recommended way to define a column
                header: '사업부',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: divisionState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemclass', //simple recommended way to define a column
                header: '계정',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: itemClassState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_current', //simple recommended way to define a column
                header: '현재고(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'amt_current', //simple recommended way to define a column
                header: '현재고(금액)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'cost_average', //simple recommended way to define a column
                header: '이동평균단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'cost_last', //simple recommended way to define a column
                header: '최종입고단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itemsource', //simple recommended way to define a column
                header: '구입선',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: itemSourceState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'unit_stock', //simple recommended way to define a column
                header: '자재단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: unitState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'unit_purchase', //simple recommended way to define a column
                header: '구매단위',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: unitState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'plan_stock', //simple recommended way to define a column
                header: '자재담당',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'plan_purchase', //simple recommended way to define a column
                header: '구매담당',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'factor_conversion', //simple recommended way to define a column
                header: '변환계수',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'warelocation', //simple recommended way to define a column
                header: '창고위치',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_begin', //simple recommended way to define a column
                header: '기초재고(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'amt_begin', //simple recommended way to define a column
                header: '기초재고(금액)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_in', //simple recommended way to define a column
                header: '입고(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'amt_in', //simple recommended way to define a column
                header: '입고(금액)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_out', //simple recommended way to define a column
                header: '불출(수량)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'amt_out', //simple recommended way to define a column
                header: '불출(금액)',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, divisionState, itemClassState, itemSourceState, unitState],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">품목상세정보</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                }}
                enableColumnOrdering
                enableEditing
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                })}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="secondary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                    >
                        등록
                    </Button>
                )}
            />
            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                divisionState={divisionState}
                itemClassState={itemClassState}
                itemSourceState={itemSourceState}
                unitState={unitState}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateFactor_conversion = (data) => data > 0 ;

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, divisionState, itemClassState, itemSourceState, unitState }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">상세정보등록</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '260px', sm: '300px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            if (column.accessorKey == 'id') {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                        disabled={true}
                                    />
                                )
                            } else if (column.accessorKey == 'division') {
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            divisionState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            } else if (column.accessorKey == 'itemclass') {
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            itemClassState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            } else if (column.accessorKey == 'itemsource') {
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            itemSourceState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            } else if (column.accessorKey == 'unit_stock') {
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            unitState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            } else if (column.accessorKey == 'unit_purchase') {
                                return (
                                    <TextField
                                        select
                                        fullWidth
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            unitState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                )
                            } else {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                )
                            }
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    상세정보 등록
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ItemBalanceTable;