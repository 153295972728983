import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit, ImageSearchTwoTone } from '@mui/icons-material';
import { makeStyles } from "@material-ui/core/styles";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import LoadingSpinner from "../../components/LoadingSpinner";
import TableDatePickerEdit from "../../containers/TableDatePickerEdit";
import ReceiptMasterTableCreate from "./ReceiptMasterTableCreate";
import config from "../../config";

const useStyles = makeStyles({
    custom: {
      color: "#ffffff",
      fontWeight: "bold"
    }
  });

function ReceiptMasterTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const [updateState, setUpdateState] = useState(new Date());

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);
    const classes = useStyles();
    const [source, setSource] = useState("");

    useEffect(() => {
        async function onLoad() {
            setIsLoading(true);
            setTableData([]);
            axiosInstance.get('/FI/receiptmaster/').then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                console.log(response.data);
                setTableData(response.data);
                setRowSelection({0:true});
                setIsLoading(false);
            }).catch(function (error) {
                setIsLoading(false);
            });
        }

        onLoad();
    }, []);

    useEffect(() => {
        if (tableInstanceRef.current.getSelectedRowModel().rows.length > 0) {
            //console.info(tableInstanceRef.current.getSelectedRowModel().rows[0].original.subject);
            if (tableInstanceRef.current.getSelectedRowModel().rows[0].original.filemodels.length > 0) {
                console.log(`${config.DJANGO_URL}` + tableInstanceRef.current.getSelectedRowModel().rows[0].original.filemodels[0].file_save_name);
                //setSource(`${config.DJANGO_URL}` + tableInstanceRef.current.getSelectedRowModel().rows[0].original.filemodels[0].file_save_name);
            //} else {
            //    setSource("")
            }
        }
    }, [rowSelection]);

    useEffect(() => {
        //console.log(selectedItemBalanceAppContext.id);
        setTableData([]);
        axiosInstance.get('/FI/receiptmaster/').then(function (response) {
            //console.log(response.data);
            if(response.data) {
                setTableData(response.data);
                setRowSelection({0:true});
            }
        }).catch(function (error) {
            onError(error);
        });
    }, [updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values);
            axiosInstance.put(`/FI/receiptmaster/${values.id}/`,
            {
                empno: values.empno,
                empname: values.empname,
                is_manager: values.is_manager,
                startdate: values.startdate,
                enddate: values.enddate,
                remarks: values.remarks,
            }).then(function (response) {
                setUpdateState(new Date());
            }).catch(function (error) {
                //console.log(error);
                onError(error);
            });
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`해당직원을 삭제하시겠습니까? ${row.getValue('empno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/FI/receiptmaster/${row.getValue('id')}/`, row.original).then(function (response) {
                setUpdateState("delete");
            }).catch(function (error) {
                //console.log(error);
                onError(error);
                setIsLoading(false);
            });
        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'subject', //simple recommended way to define a column
                header: '제목',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'payamount', //simple recommended way to define a column
                header: '결제금액',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'receiptdate', //simple recommended way to define a column
                header: '결제일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="결제일자"
                    />
                )
            },
            {
                accessorKey: 'content', //simple recommended way to define a column
                header: '내용',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                header: '사진',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell, row }) => <div>
                    {row.original.filemodels.length > 0 &&
                    <Box
                        sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        }}
                    >
                        <img
                        alt="avatar"
                        height={30}
                        src={`${config.DJANGO_URL}` + row.original.filemodels[0].file_save_name}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                        />
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{cell.getValue()}</span>
                    </Box>
                  }
                </div>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
      };
      
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };

    const handleViewPhoto = (row) => {
        if (row.original.filemodels.length > 0) {

        }
        //console.log(`${config.DJANGO_URL}` + row.original.filemodels[0].file_save_name);
    }

    return (
        <>
        <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
            <Typography variant="h6" color="white">영수증관리</Typography>
        </Box>
        {isLoading ? <LoadingSpinner /> : ""}
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                  }}
                enableColumnOrdering
                enableEditing
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                  })}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="top" title="확대">
                            <IconButton
                                color="primary"
                                onClick={() =>
                                    handleViewPhoto(row)
                                }
                            >
                                <ImageSearchTwoTone />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            CSV
                        </Button>
                    </Box>
                )}
            />
            <ReceiptMasterTableCreate
                columns={columns}
                setUpdateState={setUpdateState}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default ReceiptMasterTable;