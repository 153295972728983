import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Figure } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import { CKEditor } from 'ckeditor4-react';
import TableDateTimeCommon from "../TableDateTimeCommon";
import moment from "moment";
import 'moment/locale/ko';

export default function ReceiptMasterInfo() {
  const file = useRef(null);
  const { id } = useParams();
  const history = useHistory();
  const [note, setNote] = useState(null);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [receiptdate, setReceiptdate] = useState(new Date());
  const [payamount, setPayamount] = useState(0);
  const [receiptinfo, setReceiptinfo] = useState({ "default": "default" });
  const [source, setSource] = useState("");

  useEffect(() => {
    try {
      axiosInstance.get(`/FI/receiptmaster/${id}/`).then(function (response) {
        //console.log(response.data);
        const note = response.data;
        const { content } = note;
        setContent(content);
        setSubject(note.subject);
        setReceiptdate(new Date(note.receiptdate));
        setPayamount(note.payamount);
        if (note.filemodels.length > 0) {
          setSource(`${config.DJANGO_URL}` + note.filemodels[0].file_save_name);
        }
        setNote(note);
      }).catch(function (error) {
        onError(error);
      });
    } catch (e) {
      onError(e);
    }
  }, [id]);

  function validateForm() {
    return subject.length > 0 && payamount > 0 && content.length > 0;
  }

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  async function handleFileChange(event) {
    file.current = event.target.files;
    //console.log(file.current);
    // Loop through files
    setFileNames([]);
    //for (let i = 0; i < file.current.length; i++) {
    //async 인경우에 병렬로 처리하기 위한 방법
    for (const item of file.current) {
      let formData = new FormData();
      formData.append("file_name", item);
      //axiosInstance.post(`${config.DJANGO_URL}/fileupload/`, formData ).then(function(response) {
      //console.log(response.data);
      axiosInstance.post('/fileupload/', formData).then(function (response) {
        if (typeof response === 'undefined') throw "에러처리루틴 완료";
        setFileNames(fileNames => [...fileNames, response.data]);
      }).catch(function (error) {
        //console.log(error);
        setIsLoading(false);
      });
    }
  }

  function saveNote(note) {
    axiosInstance.put(`/FI/receiptmaster/${id}/`, note).then(function (response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      return;
    }).catch(function (error) {
      //console.log(error);
      setIsLoading(false);
    });
  }

  function deleteAttach(oldfile) {
    //delete existing attachfile
    axiosInstance.delete(`/fileupload/${oldfile}/`).then(function (response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      return;
    }).catch(function (error) {
      //console.log(error);
      
    });
  }

  function createNote(fileArray) {
    //console.log(fileArray);
    axiosInstance.post('/FI/receiptmaster/', {
      subject: subject,
      payamount: payamount,
      receiptdate: moment(receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
      receiptinfo: receiptinfo,
      content: content,
      filemodels: fileArray
    }).then(function (response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      history.push("/notes");
    }).catch(function (error) {
      //console.log(error);
     
      setIsLoading(false);
    });
  }

  async function handleSubmit(event) {
    let attachment;

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      if (file.current) {
        var fileArray = [];
        fileNames.map((data, index) => {
          var fileid = {};
          fileid.id = data.id
          fileArray.push({ ...fileid });
        })
        // arraylist 생성 필요 "filemodels": [{"id": "2"},{"id": "3"},{"id": "4"}]
        // axios 는 async 함수로 배열처리시에 지연이 발생소지가 있어 arraylist생성이 바로 안됨.
        // 따라서 파일선택시에 업로드 처리하고 생성/수정 버튼 클릭시에 노트에 파일 연결작업을 진행함.
        if (fileArray) {
          createNote(fileArray);
        }
      } else {
        attachment = note.filemodels;
        saveNote({
          subject: subject,
          payamount: payamount,
          receiptdate: moment(receiptdate).format('YYYY-MM-DDTHH:mm:ss'),
          receiptinfo: receiptinfo,
          content: content,
          filemodels: attachment,
        });
      }

      if (file.current && note.filemodel != null) {
        //delete existing attachfile
        deleteAttach(note.filemodels);
      }

      history.push("/receiptmaster");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function deleteNote() {
    return axiosInstance.delete(`/FI/receiptmaster/${id}/`, note).then(function (response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      return;
    }).catch(function (error) {
      //console.log(error);
    
      setIsLoading(false);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this receipt?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteNote();

      if (note.filemodels != null) {
        //delete existing attachfile
        note.filemodels.map((data, index) => {
          deleteAttach(data.id);
        })
      }

      history.push("/receiptmaster");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  function onEditorChange(evt) {
    setContent(evt.editor.getData());
  }

  function onChangeReceiptdate(event) {
    setReceiptdate(event);
  }

  return (
    <div className="NoteInfo">
      {note && (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row xs={12}>
              {source &&
                <Figure>
                  <Figure.Image src={source} width={300} height={300} />
                </Figure>}

            </Row>
          </Container>
          <Form.Group controlId="subject">
            <Form.Label><span><i class="fas fa-angle-double-right"></i> 제목</span></Form.Label>
            <Form.Control
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              isValid={subject.length > 0}
              isInvalid={subject.length === 0}
            />
            <Form.Control.Feedback>Looks good</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">제목을 입력해주세요</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="payamount">
            <Form.Label><span><i class="fas fa-angle-double-right"></i> 결제금액</span></Form.Label>
            <Form.Control
              type="number"
              value={payamount}
              onChange={(e) => setPayamount(e.target.value)}
              isValid={payamount > 0}
              isInvalid={payamount === 0}
            />
            <Form.Control.Feedback type="invalid">결제금액을 입력해주세요</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="receiptdate">
            <Form.Label><span><i class="fas fa-angle-double-right"></i> 결제일자</span></Form.Label>
            {(receiptdate.length != 0) && (
              <TableDateTimeCommon
                wrapperClassName="datepicker"
                selected={receiptdate}
                onChange={onChangeReceiptdate}
              />
            )}
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
            <CKEditor
              initData={content}
              onChange={onEditorChange}
            />
          </Form.Group>
          <hr />
          <Form.Group controlId="file">
            <Form.Label><span><i class="fas fa-angle-double-right"></i> Attachment</span></Form.Label>
            {note.filemodels && (
              note.filemodels.map((data, index) => {
                return (
                  <>
                    <p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${config.DJANGO_URL}` + data.file_save_name}
                      >
                        {index + 1}{"   "}{data.id}{data.file_origin_name}
                      </a>
                    </p>
                  </>);
              })
            )}
            <Form.Control onChange={handleFileChange} type="file" />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Save
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        </Form>
      )}
      <div>
        <br /><br /><br />
      </div>
    </div>
  );

}