import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationMM() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            <Nav fill variant="pills">
                <LinkContainer to="/mmmanage" className="nav-link">
                    <Nav.Link>품목관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/companymanage" className="nav-link">
                    <Nav.Link>업체관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/purmanage" className="nav-link">
                    <Nav.Link>구매관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/bommanage" className="nav-link">
                    <Nav.Link>BOM관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/bomstdcost" className="nav-link">
                    <Nav.Link>표준재료비조회</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/iteminventorycountingmanage" className="nav-link">
                    <Nav.Link>재고실사</Nav.Link>
                </LinkContainer>
            </Nav>
        </div>

    );
}

export default SubNavigationMM;