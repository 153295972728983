import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationSD() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            <Nav fill variant="pills">
                <LinkContainer to="/sdmanage" className="nav-link">
                    <Nav.Link>영업수주관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/salesplanmonth" className="nav-link">
                    <Nav.Link>판매계획</Nav.Link>
                </LinkContainer>
            </Nav>
        </div>

    );
}

export default SubNavigationSD;