import React, { useRef, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeConsumer } from "styled-components";
import { getYear, getMonth } from "date-fns"; // getYear, getMonth 
import DatePicker, { registerLocale } from "react-datepicker";  // 한국어적용
import ko from 'date-fns/locale/ko'; // 한국어적용
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosApi";
import * as dateFns from "date-fns";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  InputLabel,
} from '@mui/material';
import { disable } from "workbox-navigation-preload";

registerLocale("ko", ko) // 한국어적용
const _ = require('lodash');

const Wrap = styled.div`
  .saturday { color: rgb(0, 0, 255); } 
  .sunday { color: rgb(255, 0, 0); } 
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    background-color: #E9ECEF;
    border-color: transparent;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
`;

export default function TableDatePickerEdit(props) {
 const { t } = useTranslation();
 const [startDate, setStartDate] = useState(new Date()); 
 const [value, setValue] = useState(() => props.cell.getValue())

 // 월/일 
 const getFormattedDate = (date) => { 
     const month = date.toLocaleDateString('ko-KR', { 
         month: 'long', 
     }); 
     const day = date.toLocaleDateString('ko-KR', { 
         day: 'numeric', 
     }); 
     return `${month.substr(0, month.length - 1)}/${day.substr(0, day.length - 1)}`; 
  } 
  // 요일 반환 
  const getDayName = (date) => { 
      return date.toLocaleDateString('ko-KR', { 
          weekday: 'long', 
      }).substr(0, 1); 
  } // 날짜 비교시 년 월 일까지만 비교하게끔 
  const createDate = (date) => { 
      return new Date(new Date(date.getFullYear() 
        , date.getMonth() 
        , date.getDate() 
        , 0 
        , 0 
        , 0)); 
  }
  
  const filterPassedDay = (time) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1)
    const selectedDate = new Date(time);

    return currentDate < selectedDate;
  };
  
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleChange = (date) => {
    if (date) {
      setValue(date);
      props.row._valuesCache[props.cell.column.id] = dateFns.format(date,"yyyy-MM-dd");
    } else {
      setValue("");
      props.row._valuesCache[props.cell.column.id] = "";
    }
  }

 return (
   <Stack spacing={1}>
    {props.labelText && (<InputLabel shrink>{props.labelText}</InputLabel>)}
    <DatePicker
        className="react-datepicker"
        placeholderText={t('DATE001')}
        showIcon
        showYearDropdown
        scrollableMonthYearDropdown
        minDate={new Date()}
        dateFormat="yyyy-MM-dd"
        locale={ko}
        selected={value ? new Date(value) : null}
        isClearable
        onChange={(date) => handleChange(date)}
        // 토요일, 일요일 색깔 바꾸기 위함 
        dayClassName={date => 
            getDayName(createDate(date)) === '토' ? "saturday" 
            : 
            getDayName(createDate(date)) === '일' ? "sunday" : undefined 
        }
    />
  </Stack>
 );
}