import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ItemTransactionTableCreate from "./ItemTranscationTableCreate";

function ItemTransactionTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemBalanceAppContext, setSelectedItemBalanceAppContext } = useAppContext();
    const { selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {slipTypeState, setSlipTypeState} = useAppContext();
    const {releaseState, setReleaseState} = useAppContext();
    const {returnState, setReturnState} = useAppContext();
    const [updateState, setUpdateState] = useState(new Date());

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemBalanceAppContext.id);
        setTableData([]);
        if (selectedItemBalanceAppContext) {
            axiosInstance.get('/MM/itemtransaction/', {
                params: { itembalance_id: selectedItemBalanceAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                //console.log(response.data);
                setTableData(response.data);
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        }
    }, [selectedItemBalanceAppContext, updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values.id);
            axiosInstance.put(`/MM/itemtransaction/${values.id}/`, {
                itembalance: selectedItemBalanceAppContext,
                raise_date: values.raise_date,
                sliptype: values.sliptype,
                returngubun: values.returngubun,
                releasegubun: values.releasegubun,
                qty_raise: values.qty_raise,
                amt_raise: values.amt_raise,
                remarks: values.remarks,
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                tableData[row.index] = values;
                setTableData([...tableData]);
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`해당품목을 삭제하시겠습니까? ${row.getValue('itemno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/MM/itemtransaction/${row.getValue('id')}/`, row.original).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
                setSelectedItemBalanceUpdateAppContext(new Date());
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'sliptype', //simple recommended way to define a column
                header: '전표구분',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: slipTypeState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'raise_date', //simple recommended way to define a column
                header: '발생일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'qty_raise', //simple recommended way to define a column
                header: '수량',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'amt_raise', //simple recommended way to define a column
                header: '금액',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'releasegubun', //simple recommended way to define a column
                header: '불출구분',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: releaseState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'returngubun', //simple recommended way to define a column
                header: '반납구분',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: returnState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'department.id', //simple recommended way to define a column
                header: '부서ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'department.deptname', //simple recommended way to define a column
                header: '부서명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'companymaster.id', //simple recommended way to define a column
                header: '업체ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'companymaster.name_kor', //simple recommended way to define a column
                header: '업체명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'email',
                }),
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, slipTypeState, releaseState, returnState],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">품목수불정보</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                }}
                enableColumnOrdering
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                })}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
            />
            <ItemTransactionTableCreate
                columns={columns}
                setUpdateState={setUpdateState}
                slipTypeState={slipTypeState}
                releaseState={releaseState}
                returnState={returnState}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default ItemTransactionTable;