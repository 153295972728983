import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import TableDatePickerEdit from "../TableDatePickerEdit";
import WipCurrentTableCreate from "./WipCurrentTableCreate";

function WipCurrentTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();

    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {wipState, setWipState} = useAppContext();
    const [updateState, setUpdateState] = useState(new Date());

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemAppContext.id);
        setTableData([]);
        axiosInstance.get('/PP/wipcurrent/').then(function (response) {
            console.log(response.data);
            setTableData(response.data);
            //setRowSelection({ 0: true });
        }).catch(function (error) {
            onError(error);
        });
    }, [selectedItemAppContext, updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values.id);
            axiosInstance.put(`/PP/wipcurrent/${values.id}/`, values).then(function (response) {
                tableData[row.index] = values;
                setTableData([...tableData]);
            }).catch(function (error) {
                //console.log(error);
                onError(error);
                setIsLoading(false);
            });
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`Are you sure you want to delete ${row.getValue('itemno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/PP/wipcurrent/${row.getValue('id')}/`, row.original).then(function (response) {
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
            }).catch(function (error) {
                //console.log(error);
                onError(error);
                setIsLoading(false);
            });
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [

            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'itembalance.id', //simple recommended way to define a column
                header: '품목ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itembalance.itemmaster.itemno', //simple recommended way to define a column
                header: '품번',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'itembalance.itemmaster.itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wiocd', //simple recommended way to define a column
                header: '재공구분',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: wipState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'wcompany.id', //simple recommended way to define a column
                header: '업체ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wcompany.name_kor', //simple recommended way to define a column
                header: '업체명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wcost', //simple recommended way to define a column
                header: '재공단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wbgqt', //simple recommended way to define a column
                header: '기초수량',
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wbgat', //simple recommended way to define a column
                header: '기초금액',
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'winqt', //simple recommended way to define a column
                header: '투입수량',
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                id: 'winat', //simple recommended way to define a column
                header: '투입금액',
                accessorFn: (row) => (row.winat1 + row.winat2 + row.winat3), //accessorFn used to join multiple data into a single cell
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            },
            {
                id: 'wusqt', //simple recommended way to define a column
                header: '사용수량',
                accessorFn: (row) => (Number(row.wusqt1) + Number(row.wusqt2) + Number(row.wusqt3) + Number(row.wusqt4) + Number(row.wusqt5) + Number(row.wusqt6) + Number(row.wusqt7) + Number(row.wusqt8)), //accessorFn used to join multiple data into a single cell
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            },
            {
                id: 'wusat', //simple recommended way to define a column
                header: '사용금액',
                accessorFn: (row) => (row.wusat1 + row.wusat2 + row.wusat3 + row.wusat4 + row.wusat5 + row.wusat6 + row.wusat7 + row.wusat8 + row.wusat9), //accessorFn used to join multiple data into a single cell
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            },
            {
                accessorKey: 'wohqt', //simple recommended way to define a column
                header: '현재공수량',
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wohat', //simple recommended way to define a column
                header: '현재공금액',
                muiTableHeadCellProps: { sx: { color: 'blue' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'winat1', //simple recommended way to define a column
                header: '투입금액1',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'winat2', //simple recommended way to define a column
                header: '투입금액2',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'winat3', //simple recommended way to define a column
                header: '투입금액3',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wusqt1', //simple recommended way to define a column
                header: '사용수량1',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'wusat1', //simple recommended way to define a column
                header: '사용금액1',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, wipState],
    );

    return (
        <>
            <Typography variant="h5">재공수불현황(당월)</Typography>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                }}
                enableColumnOrdering
                enableEditing
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                })}
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            />
            <WipCurrentTableCreate
                columns={columns}
                selectedItemAppContext={selectedItemAppContext}
                setUpdateState={setUpdateState}
                wipState={wipState}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default WipCurrentTable;