import TextField from "@material-ui/core/TextField";
import { Controller } from "react-hook-form";
import React from "react";

export const ControlInputText = ({ name, control, rules, label, type, defaultValue, disabled }) => {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field: { onChange, value, ref } }) => (
            <TextField
                fullWidth
                variant="standard"
                type={type}
                inputProps={{
                    autoComplete: "new-password",
                }}
                label={label}
                inputRef={ref}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
            />
        )}
    />
};