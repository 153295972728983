import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    InputLabel,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import TableDatePickerEdit from "../../containers/TableDatePickerEdit";
import DepartmentTableCreate from "./DepartmentTableCreate";

function DepartmentTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);

    const [seletedParentItem, setSeletedParentItem] = useState(1);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {supplyGubunState, setSupplyGubunState} = useAppContext();
    const {divisionState, setDivisionState} = useAppContext();

    const [updateState, setUpdateState] = useState(new Date());

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemBalanceAppContext.id);
        setTableData([]);
        axiosInstance.get('/HR/department_explosion/', {
            params: { department_id: 1 }
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            //console.log(JSON.parse(response.data));
            setTableData(tableData => [...tableData, JSON.parse(response.data)]);
        }).catch(function (error) {
            //onError(error);
        });
    }, [updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            console.log(values);
            axiosInstance.put(`/HR/department/${values.id}/`,
            {
                deptname: values.deptname,
                startdate: values.startdate,
                enddate: values.enddate,
                remarks: values.remarks,
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setUpdateState(new Date());
            }).catch(function (error) {
                //console.log(error);
            });
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`해당부서를 삭제하시겠습니까? ${row.getValue('deptname')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/HR/department//${row.getValue('id')}/`, row.original).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";    
                setUpdateState("delete");
            }).catch(function (error) {
                //console.log(error);
                setIsLoading(false);
            });
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellProps: ({ cell }) => ({
                    onClick: () => {
                      console.log(cell.getValue(), cell.id);
                      setSeletedParentItem(cell.getValue());
                    },
                  }),
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'deptname', //simple recommended way to define a column
                header: '부서명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'startdate', //simple recommended way to define a column
                header: '시작일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="시작일자"
                    />
                )
            },
            {
                accessorKey: 'enddate', //simple recommended way to define a column
                header: '종료일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="종료일자"
                    />
                )
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 100,
            },
            {
                accessorKey: 'parentdept.id', //simple recommended way to define a column
                header: '상위부서ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
        ],
        [getCommonEditTextFieldProps, divisionState, supplyGubunState],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">부서정보</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 100,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                    expanded: true
                }}
                enableExpandAll={false} //hide expand all double arrow in column header
                enableExpanding
                filterFromLeafRows //apply filtering to all rows instead of just parent rows
                enableColumnOrdering
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                renderRowActions={({ row, table }) => ( 
                    !(row.original.id == 1) && (<Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>)
                )}
            />
           <DepartmentTableCreate
                columns={columns}
                seletedParentItem={seletedParentItem}
                setUpdateState={setUpdateState}
            /> 
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default DepartmentTable;