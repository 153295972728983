import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import TableDatePickerEdit from "../TableDatePickerEdit";

const ItemCostTableFind = ({ setValue, columnName }) => {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const [itemState, setItemState] = useState([]);
    const [purchaseState, setPurchaseState] = useState([]);
    const [unitState, setUnitState] = useState([]);
    const [updateState, setUpdateState] = useState(new Date());

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {
            setTableData([]);
            axiosInstance.get('/MM/itemcost/').then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setTableData(response.data);
                setRowSelection({ 0: true });
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.info(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
        if (tableInstanceRef.current.getSelectedRowModel().rows.length > 0) {
            //console.log(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
            setValue(columnName, tableInstanceRef.current.getSelectedRowModel().rows[0].original.id);
        }
    }, [rowSelection]);

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'companymaster.id', //simple recommended way to define a column
                header: '업체ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                size: 80,
            },
            {
                accessorKey: 'itemmaster.id', //simple recommended way to define a column
                header: '품번ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                size: 80,
            },
            {
                accessorKey: 'itemmaster.itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'domcost', //simple recommended way to define a column
                header: '단가',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'domcurrency', //simple recommended way to define a column
                header: '통화',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'domdate', //simple recommended way to define a column
                header: '시작일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="시작일자"
                    />
                )
            },
            {
                accessorKey: 'domsheet', //simple recommended way to define a column
                header: '품의번호',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, itemState, purchaseState],
    );

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={tableData}
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                  }}
                enableColumnOrdering
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                  })}
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
            />
            <div /><div /><div />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default ItemCostTableFind;