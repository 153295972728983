import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

function PurMasterTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {divisionState, setDivisionState} = useAppContext();
    const {ingstatusState, setIngstatusState} = useAppContext();

    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {
            setTableData([]);
            axiosInstance.get('/MM/purmaster/').then(function (response) {
                //console.log(response.data);
                setTableData(response.data);
                setRowSelection({0:true});
            }).catch(function (error) {
                onError(error);
            });
        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.info(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
        if (tableInstanceRef.current.getSelectedRowModel().rows.length > 0) {
            setSelectedItemAppContext(tableInstanceRef.current.getSelectedRowModel().rows[0].original);
        }
      }, [rowSelection]);

    const handleCreateNewRow = (values) => {
        //console.log(values);
        // call axios post api
        axiosInstance.post(`/MM/purmaster/`, {
            requestno: values.requestno ? values.requestno : "",
            division: values.division,
            requser: values.requser ? values.requser : "",
            projectno: values.projectno ? values.projectno : "",
            ingstatus: values.ingstatus,
            remarks: values.remarks ? values.remarks : "",
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            tableData.push(response.data);
            setTableData([...tableData]);
        }).catch(function (error) {
            setIsLoading(false);
        });
        setIsLoading(false);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            //console.log(values.id);
            axiosInstance.put(`/MM/purmaster/${values.id}/`, 
                {
                    requestno: values.requestno,
                    division: values.division,
                    requser: values.requser,
                    projectno: values.projectno,
                    ingstatus: values.ingstatus,
                    remarks: values.remarks,
                }
            ).then(function (response) {
                tableData[row.index] = values;
                setTableData([...tableData]);
            }).catch(function (error) {
                //console.log(error);
                if (error && error.response && error.response.status) {
                    if (error.response.status === 444) {
                        onError(error.response.data.message);
                    } else {
                        onError(error);
                    }
                } else {
                    onError(error);
                }
                setIsLoading(false);
            });
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`Are you sure you want to delete ${row.getValue('itemno')}`)) {
                return;
            }
            //console.log(row.original);
            axiosInstance.delete(`/MM/purmaster/${row.getValue('id')}/`, row.original).then(function (response) {
                tableData.splice(row.index, 1);
                setTableData([...tableData]);
            }).catch(function (error) {
                //console.log(error);
                onError(error);
                setIsLoading(false);
            });
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,
            },
            {
                accessorKey: 'requestno', //simple recommended way to define a column
                header: '구매요구번호',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'division', //simple recommended way to define a column
                header: '사업부',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: divisionState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'requser', //simple recommended way to define a column
                header: '구매요구자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'projectno', //simple recommended way to define a column
                header: '프로젝트번호',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'ingstatus', //simple recommended way to define a column
                header: '진행상태',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: ingstatusState.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, divisionState, ingstatusState],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">구매요구정보</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                  }}
                enableColumnOrdering
                enableEditing
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                  })}
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                renderRowActions={({ row, table }) => (
                    !(row.original.ingstatus == "완료") && (<Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>)
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="secondary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                    >
                        구매요구 등록
                    </Button>
                )}
            />
            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                divisionState={divisionState}
                ingstatusState={ingstatusState}
            />
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, divisionState, ingstatusState }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">신규 구매요구</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '260px', sm: '300px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map((column) => {
                            if (column.accessorKey == 'id') {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        disabled
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                );
                            } else if (column.accessorKey == 'crt_emp.email') {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        disabled
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                );
                            } else if (column.accessorKey == 'crt_date') {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        disabled
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                );
                            } else if (column.accessorKey == 'requestno') {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        disabled
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                );
                            } else if (column.accessorKey == 'division') {
                                return (
                                    <TextField
                                        select
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            divisionState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                );
                            } else if (column.accessorKey == 'ingstatus') {
                                return (
                                    <TextField
                                        select
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    >
                                        {
                                            ingstatusState.map((state) => (
                                                <MenuItem key={state} value={state}>
                                                    {state}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                );
                            } else {
                                return (
                                    <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                                );
                            }
                            })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    구매요구 등록
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PurMasterTable;