import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Alert,
    Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlInputText } from "../../components/ControlInputText";
import { ControlCheckbox } from "../../components/ControlCheckbox";
import { ControlDatePicker } from "../../components/ControlDatePicker";
import * as dateFns from "date-fns";
import ItemTransactionTable from "./ItemTransactionTable";
import { ControlInputTextMenuItem } from "../../components/ControlInputTextMenuItem";
import DepartmentTableFind from "../hr/DepartmentTableFinde";
import CompanyMasterTableFind from "../mm/CompanyMasterTableFind";

//example of creating a mui dialog modal for creating new rows
const ItemTransactionTableCreate = ({ columns, setUpdateState, slipTypeState, releaseState, returnState }) => {
    const { register, handleSubmit, formState, getValues, setValue, control } = useForm();
    const { selectedItemBalanceAppContext, setSelectedItemBalanceAppContext } = useAppContext();
    const { selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext } = useAppContext();
    const [releasegubun, setReleasegubun] = useState("정상");
    const [isLoading, setIsLoading] = useState(true);
    const { isSubmitting } = formState;
    const { t } = useTranslation();

    const onChangeBillingForm = () => {
        const regex = /^[0-9\b -]{0,13}$/;
        var f_data = getValues()
        if (f_data.releasegubun){
            setReleasegubun(f_data.releasegubun);
        }
        return;
    }

    const onSubmit = (data) => {
        //put your validation logic here
        console.log(data);
        // call axios post api
        axiosInstance.post(`/MM/itemtransaction/`, {
            itembalance: selectedItemBalanceAppContext.id,
            department: data.department.id,
            companymaster: data.companymaster.id,
            raise_date: dateFns.format(data.raise_date,"yyyy-MM-dd"),
            sliptype: data.sliptype,
            returngubun: data.returngubun,
            releasegubun: data.releasegubun,
            qty_raise: data.qty_raise,
            amt_raise: 0,
            remarks: data.remarks,
        }).then(function (response) {
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            setUpdateState(new Date());
            setSelectedItemBalanceUpdateAppContext(new Date());
        }).catch(function (error) {
            setIsLoading(false);
        });
        setIsLoading(false);
    };

    return (
        <>

                <Stack
                    sx={{
                        width: '100%',
                        minWidth: { xs: '260px', sm: '300px', md: '400px' },
                        gap: '1.5rem',
                        border: 2,
                        padding: 0.5,
                        marginTop: 2,
                        marginBottom: 2
                    }}
                >
                    {(!formState.isValid && formState.isSubmitted) ?
                        <Alert severity="error"   >
                            {Object.values(formState.errors).map((e, idx) => {
                                return (<p key={idx}>{e.message}</p>)
                            })}
                        </Alert>
                        :
                        <Alert severity="success">불출/반납/입고/입고취소 전표를 입력해 주세요</Alert>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        {columns.map((column) => {
                            if (column.accessorKey == 'sliptype') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputTextMenuItem
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "전표구분을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue=''
                                            itemStatus={slipTypeState}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'releasegubun') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputTextMenuItem
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "불출구분을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue=''
                                            itemStatus={releaseState}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'department.id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "부서을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'department.deptname') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "부서을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="소네트CEO"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'companymaster.id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "업체을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'companymaster.name_kor') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "업체을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'returngubun') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputTextMenuItem
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "반납구분을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue=''
                                            itemStatus={returnState}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'raise_date') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlDatePicker
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "발생일자를 선택해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'qty_raise') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "수량을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'remarks') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "특이사항을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                            
                            <Grid item xs={12} md={6}>
                                <DialogActions sx={{ p: '1.25rem' }}>
                                    <Button color="secondary" type="submit" variant="contained">
                                        전표등록
                                    </Button>
                                </DialogActions>
                            </Grid>

                        </Grid>

                    </form>

                </Stack>
                { (releasegubun == '무상사급' || releasegubun == '유상사급') ? (
                    <CompanyMasterTableFind
                        setValue={setValue}
                        columnId='companymaster.id'
                        columnName='companymaster.name_kor'
                    />
                ) : (
                    <DepartmentTableFind
                        setValue={setValue}
                        columnId='department.id'
                        columnName='department.deptname'
                    />
                )
                }
        </> 
    );
};

export default ItemTransactionTableCreate;