import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationPP() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            <Nav fill variant="pills">
                <LinkContainer to="/ppmanage" className="nav-link">
                    <Nav.Link>월간생산계획</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/mrpmanage" className="nav-link">
                    <Nav.Link>자재소요계획(MRP)</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/wipcurrentmanage" className="nav-link">
                    <Nav.Link>재공수불관리(당월)</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/wipmonthmanage" className="nav-link">
                    <Nav.Link>재공수불관리(전월)</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/wipclosemanage" className="nav-link">
                    <Nav.Link>재공마감관리</Nav.Link>
                </LinkContainer>
            </Nav>
        </div>

    );
}

export default SubNavigationPP;