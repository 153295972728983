import React, { useState } from "react";
import { Nav, Navbar, Stack, Form, Row, Col, Offcanvas, Button } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import kakaoLogo from "../images/kakao_logo.png";
import naverLogo from "../images/naver_logo.png";
import { KAKAO_AUTH_URL } from "../components/OAuth";
import { useTranslation } from 'react-i18next';
import config from "../config";
import { setToken } from "../firebase";

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const NaverShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;
  
const KakaoShareButton = styled.a`
    cursor: pointer;
    border-radius: 24px;
	border: 0px;
    &:hover {
		background-color: #4177c6;
	}
  `;

const KakaoIcon = styled.img`
	width: 48px;
	height: 48px;
	border-radius: 24px;
  `;

function GetEmail({ name, ...props }) {
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const handleClose = () => {
      setShow(false);
      props.onChange(email);
  }
  
  const handleShow = () => {
      setShow(true);
  }
  
  function validateForm() {
    return phone.length > 0 ;
  }

  function handleSubmitFind(event) {
    event.preventDefault();

    axios.get(`${config.DJANGO_URL}/accounts/get_user_by_phone/`, {
              params: {  
                  phone_number: phone
              }
    }).then(function(response) {
          setEmail(response.data[0].email);
    }).catch(function (error) {
        onError(t('READ_FAIL'));
    });
  }
  
  return (
    <>
      <button type="button" class="LoaderButton  btn btn-dark btn-lg" onClick={handleShow} >{name}</button>
      
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white bg-dark">{t('FINDEMAIL')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className=" border">
          <Form onSubmit={handleSubmitFind}>
            <Form.Group size="lg" controlId="phone">
              <Form.Label>{t('F_EMAIL_LABEL')}</Form.Label>
                <Form.Control
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
            </Form.Group>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={email}
                  onChange={props.onChange}
                />
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              variant="dark"
              onClick={handleSubmitFind}
              disabled={!validateForm()}
            >
              Email찾기
            </LoaderButton>
          </Form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function LoginBasic() {
  const { t } = useTranslation();
  const { user, setUser } = useAppContext();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [message, setMessage] = useState(t('AUTHMSG'));
  const [isConfirm, setIsConfirm] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    authcode: "",
  });

  function validateForm() {
    return email.length > 0 && password.length > 0 ;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    axios.post(`${config.DJANGO_URL}/accounts/login/`, {
                email: email,
                password: password
    }).then(function(response) {
          axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access_token;
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('refresh_token', response.data.refresh_token);
          localStorage.setItem('access_user', JSON.stringify(response.data.user));
          setUser(response.data.user);
          userHasAuthenticated(true);
          setToken('robotaxi',response.data.user.email);
          history.push("/");

    }).catch(function (error) {
      if (typeof error === 'object') {
        //console.log(error.response);
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        }
      }
      //console.log(error);
      onError(t('LOGIN_FAIL'));
      userHasAuthenticated(false);
      setIsLoading(false);
    });
  }
  
  async function handlePasswordReset(event) {
    window.open(`${config.DJANGO_URL}/auth/password_reset/`);
  }
  
  return (
  <Stack gap={3}>
    <div className="bg-light border">
        <Nav sticky="top">
          {/*
              <LinkContainer to="/loginsocial">
                  <Nav.Link>SNS {t('LOGIN')}</Nav.Link>
              </LinkContainer>
          */}
              <LinkContainer to="/loginbasic">
                  <Nav.Link>E-mail {t('LOGIN')}</Nav.Link>
              </LinkContainer>
        </Nav>
    </div>
    <div className="bg-light border">
      <div className="Login">
        <Form onSubmit={handleSubmit}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>{t('EMAIL')}</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>{t('PASSWORD')}</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="dark"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            {t('LOGIN')}
          </LoaderButton>
          <LoaderButton
            block
            size="lg"
            variant="dark"
            onClick={handlePasswordReset}
            isLoading={isLoading}
          >
            {t('PASSWORD_RESET')}
          </LoaderButton>  
          <GetEmail key={1} placement='bottom' 
                            name={t('FINDEMAIL')}
                            onChange={setEmail} 
                             />
        </Form>
      </div>
    </div>
    <div>
        <br /><br /><br />
    </div>
  </Stack>
  );
}