import React, { useRef, useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import config from "../config";
import "./NoteNew.css";
import axios from "axios";
import axiosInstance from "../axiosApi";
import { CKEditor } from 'ckeditor4-react';
import ToggleSwitch from "../components/ToggleSwitch";
import TableDateRange from "./TableDateRange";
import * as dateFns from "date-fns";

export default function NoteNew() {
  const file = useRef(null);
  const history = useHistory();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [subject, setSubject] = useState("");
  const [is_top, setIs_top] = useState(false);
  const [is_publish, setIs_publish] = useState(false);
  const [is_popup, setIs_popup] = useState(false);
  const [s_date, setS_date] = useState(new Date());
  const [e_date, setE_date] = useState(new Date());

  const onSwitchActionTop = () => {
    setIs_top(!is_top);
    //console.log(isSwitchOnService);
  };

  const onSwitchActionPublish = () => {
    setIs_publish(!is_publish);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionPopup = () => {
    setIs_popup(!is_popup);
    //console.log(isSwitchOnPrivate);
  };
  
  function validateForm() {
    return content.length > 0;
  }

  async function handleFileChange(event) {
    file.current = event.target.files;
    console.log(file.current);
    // Loop through files
    setFileNames([]);
    //for (let i = 0; i < file.current.length; i++) {
    //async 인경우에 병렬로 처리하기 위한 방법
    for ( const item of file.current ) {
      let formData = new FormData();
      formData.append("file_name", item);
      //axios.post(`${config.DJANGO_URL}/fileupload/`, formData, {
      //  headers: {
      //    'Authorization': localStorage.getItem('access_token') ? "Bearer " + localStorage.getItem('access_token') : null,
      //    'Content-Type': 'multipart/form-data',
      //    'accept': 'application/json',
      //  }
      //}).then(function(response) {
      axiosInstance.post('/fileupload/', formData ).then(function(response) {
        //console.log(response.data);
        setFileNames(fileNames => [...fileNames, response.data]);
      }).catch(function (error) {
        //console.log(error);
        onError(error);
        setIsLoading(false);
      });
    }
  }

  function createNote(fileArray) {
    console.log(fileArray);
    axiosInstance.post('/CO/notes/', {
      subject: subject,
      content: content,
      is_publish: is_publish,
      is_top: is_top,
      is_popup: is_popup,
      s_date: dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00"),
      e_date: dateFns.format(e_date,"yyyy-MM-dd'T'00:00:00"),
      filemodels: fileArray
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      history.push("/notes");
    }).catch(function (error) {
      onError(error);
      setIsLoading(false);
    }); 
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);
    
    if (fileNames) {
        
        var fileArray = [];
        fileNames.map( (data, index) => {
          var fileid = {};
          fileid.id = data.id
          fileArray.push({...fileid});
        })
        // arraylist 생성 필요 "filemodels": [{"id": "2"},{"id": "3"},{"id": "4"}]
        // axios 는 async 함수로 배열처리시에 지연이 발생소지가 있어 arraylist생성이 바로 안됨.
        // 따라서 파일선택시에 업로드 처리하고 생성/수정 버튼 클릭시에 노트에 파일 연결작업을 진행함.
        if (fileArray) {
          createNote(fileArray);
        }
    }
    else {
      axiosInstance.post('/CO/notes/', {
          subject: subject,
          content: content,
          is_publish: is_publish,
          is_top: is_top,
          is_popup : is_popup,
          s_date : dateFns.format(s_date,"yyyy-MM-dd'T'00:00:00"),
          e_date : dateFns.format(e_date,"yyyy-MM-dd'T'00:00:00"),
          filemodels: null
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          history.push("/notes");
        }).catch(function (error) {
          onError(error);
          setIsLoading(false);
        }); 
    }
  }
  
  function onEditorChange( evt ) {
    setContent( evt.editor.getData() );
  }

  function onChangeStartDate(event) {
    setS_date(event);
  }
  
  function onChangeEndDate(event) {
    setE_date(event);
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="subject">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 제목</span></Form.Label>
          <Form.Control
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="policy">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
          <Container>
            <Row xs="auto">
              <Col>
                <ToggleSwitch label="공개" isChecked={is_publish} onChange={onSwitchActionPublish}/>
              </Col>
              <Col>
                <ToggleSwitch label="TOP" isChecked={is_top} onChange={onSwitchActionTop}/>
              </Col>
            </Row>
            <Row xs="auto">
              <Col>
                <ToggleSwitch label="팝업" isChecked={is_popup} onChange={onSwitchActionPopup}/>
              </Col>
              <Col>
                <TableDateRange
                    wrapperClassName="datepicker"
                    selectedStartDate={s_date}
                    onChangeStartDate={onChangeStartDate} 
                    selectedEndDate={e_date}
                    onChangeEndDate={onChangeEndDate} 
                />
              </Col>
            </Row>
          </Container>
        </Form.Group>
        <Form.Group controlId="content">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
          <CKEditor 
            initData={content} 
            onChange={onEditorChange}
          />
        </Form.Group>
        <hr/>
        <Form.Group controlId="file">
          <Form.Label><span><i class="fas fa-angle-double-right"></i> Attachment</span></Form.Label>
          <Form.Control onChange={handleFileChange} type="file" multiple />
          {fileNames.map( (data, index) => {
            return ( <><Form.Label>{data.id}{"   "}{data.file_origin_name}</Form.Label><br/></>)
          })}
        </Form.Group>
        <LoaderButton
          block
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Create
        </LoaderButton>
      </Form>
      <div>
        <br /><br /><br />
      </div>
    </div>
  );
}