import React, { useState, useMemo, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, Badge, Button, Toast } from "react-bootstrap";
import { AppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import axiosInstance from "./axiosApi";
import LangDropdown from "./components/LangDropdown";
import { getToken, onMessageListener } from "./firebase";
import axios from 'axios'
import Logo from './images/erplogo_rouded.png';
import { useTranslation } from 'react-i18next';
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillAlarmFill } from "react-icons/bs";
import config from "./config";
import { differenceInMinutes } from "date-fns";
import SubNavigation from "./components/SubNavigation";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

function App() {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');

  const [currentLocation, setCurrentLocation] = useState('/');;
  const [selectedItemAppContext, setSelectedItemAppContext] = useState('');
  const [selectedItemBalanceAppContext, setSelectedItemBalanceAppContext] = useState('');
  const [selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext] = useState(new Date());
  const [itemState, setItemState] = useState([]);
  const [purchaseState, setPurchaseState] = useState([]);
  const [unitState, setUnitState] = useState([]);
  const [itemClassState, setItemClassState] = useState([]);
  const [itemSourceState, setItemSourceState] = useState([]);
  const [divisionState, setDivisionState] = useState([]);
  const [slipTypeState, setSlipTypeState] = useState([]);
  const [releaseState, setReleaseState] = useState([]);
  const [returnState, setReturnState] = useState([]);
  const [supplyGubunState, setSupplyGubunState] = useState([]);
  const [ingstatusState, setIngstatusState] = useState([]);
  const [wipState, setWipState] = useState([]);

  const providerValue = useMemo(() => ({
    isAuthenticated, userHasAuthenticated,
    user, setUser,
    currentLocation, setCurrentLocation,
    selectedItemAppContext, setSelectedItemAppContext,
    selectedItemBalanceAppContext, setSelectedItemBalanceAppContext,
    selectedItemBalanceUpdateAppContext, setSelectedItemBalanceUpdateAppContext,
    itemState, setItemState,
    purchaseState, setPurchaseState,
    unitState, setUnitState,
    itemClassState, setItemClassState,
    itemSourceState, setItemSourceState,
    divisionState, setDivisionState,
    slipTypeState, setSlipTypeState,
    releaseState, setReleaseState,
    returnState, setReturnState,
    supplyGubunState, setSupplyGubunState,
    ingstatusState, setIngstatusState,
    wipState, setWipState,
  }), [isAuthenticated, user, currentLocation, selectedItemAppContext, selectedItemBalanceAppContext, 
    selectedItemBalanceUpdateAppContext, itemState, purchaseState, unitState, itemClassState, itemSourceState,
    divisionState, slipTypeState, releaseState, returnState, supplyGubunState, ingstatusState, wipState ]);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);
  const [payload, setPayload] = useState(null);
  const [lapseMin, setLapseMin] = useState("");
  const [alarmCount, setAlarmCount] = useState(0);
  const [messageStore, setMessageStore] = useState([]);

  getToken(setTokenFound);

  onMessageListener().then(payload => {
    if (user !== null) {
      setShow(true);
    } else {
      setShow(false);
    }
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    setPayload(payload);
    setLapseMin("just now");
    //console.log(payload);
  }).catch(err => console.log('failed: ', err));

  useEffect(() => {
    const timeout = setTimeout(() => getMessageStore(), 10000);
    return () => clearTimeout(timeout);
  }, [messageStore]);

  function getMessageStore() {  
    try {
      if (isAuthenticated) {
        axiosInstance.get('/CO/messagestorecheck/').then(function(response) {
          //console.log(response.data.length);
          if (typeof response !== 'undefined') {
            setMessageStore(response.data);
            if (isAuthenticated) {
              setAlarmCount(response.data.length)
            } else {
              setAlarmCount(0)
            }    
          }
        }).catch(function (error) {
          setAlarmCount(0)
          console.log(error);
        });
      }
    } catch (error) {
      //onError(error);
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    const response = await fetch("/assets/choiceData.json");
    const options = await response.json();
    setItemState([]);
    setPurchaseState([]);
    setUnitState([]);
    setItemClassState([]);
    setItemSourceState([]);
    setDivisionState([]);
    setSlipTypeState([]);
    setReleaseState([]);
    setReturnState([]);
    setSupplyGubunState([]);
    setIngstatusState([]);
    setWipState([]);
    options.items.map((data) => setItemState(itemState => [...itemState, data]));
    options.purchase.map((data) => setPurchaseState(purchaseState => [...purchaseState, data]));
    options.itemunit.map((data) => setUnitState(unitState => [...unitState, data]));
    options.itemclass.map((data) => setItemClassState(itemClassState => [...itemClassState, data]));
    options.itemsource.map((data) => setItemSourceState(itemSourceState => [...itemSourceState, data]));
    options.division.map((data) => setDivisionState(divisionState => [...divisionState, data]));
    options.sliptype.map((data) => setSlipTypeState(slipTypeState => [...slipTypeState, data]));
    options.release.map((data) => setReleaseState(releaseState => [...releaseState, data]));
    options.return.map((data) => setReturnState(returnState => [...returnState, data]));
    options.supplygubun.map((data) => setSupplyGubunState(supplyGubunState => [...supplyGubunState, data]));
    options.ingstatus.map((data) => setIngstatusState(ingstatusState => [...ingstatusState, data]));
    options.wipcode.map((data) => setWipState(wipState => [...wipState, data]));

    axios.post(`${config.DJANGO_URL}/accounts/token/verify/`, {
      token: localStorage.getItem("access_token"),
    }).then(function (response) {
      userHasAuthenticated(true);
      setUser(JSON.parse(localStorage.getItem("access_user")));
    }).catch(function (error) {
      //console.log(error);
    });
    setIsAuthenticating(false);
  }

  function handleLogout() {
    // add sign out login...
    localStorage.clear();
    userHasAuthenticated(false);
    setUser(null);
    history.push("/");
  }

  const onChange = event => {
    //console.log(event);
    setSelectedValue(event.target.value);
  }

  const handleSelect = (eventKey) => {
    if (eventKey === 'en' || eventKey === 'kr') {
      //console.log('A key was pressed', eventKey);
      setSelectedLang(eventKey);
      i18n.changeLanguage(eventKey);
    }
  }

  const onClickToast = () => {
    if (user !== null) {
      setShow(true);
    } else {
      setShow(false);
    }

    if (payload !== null) {
      setLapseMin(differenceInMinutes(new Date(), new Date(payload.data.crtdate)));
    }
  }

  return (
    !isAuthenticating && (
      <div className="App container-fluid py-3">
        <Toast bg="dark" onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{notification.title}</strong>
            <small>{lapseMin}{lapseMin === 'just now' ? '' : '분전'}</small>
          </Toast.Header>
          <Toast.Body className="text-white">{notification.body}</Toast.Body>
        </Toast>
        <Navbar fluid collapseOnSelect expand="md" className="mb-3 navbar-dark bg-dark px-2">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold">
              <img
                src={Logo}
                width="30"
                height="30"
                className="d-inline-block"
              />
              {'  '} {t('HOME_TITLE')} {'   '}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname} pullRight onSelect={handleSelect}>
              {((user !== null) ? user.user_type : false) ? (
                <>
                  {isAuthenticated ? (
                    <>
                      <LinkContainer to="/sdmanage">
                        <Nav.Link>영업</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/mmmanage">
                        <Nav.Link>자재/구매</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/ppmanage">
                        <Nav.Link>생산</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/hrmanage">
                        <Nav.Link>인사</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/fimanage">
                        <Nav.Link>회계</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/userdetail" className="nav-link">
                        <Nav.Link>내정보</Nav.Link>
                      </LinkContainer>
                      {/*
                      <LinkContainer to="/settings">
                        <Nav.Link>{t('SETTING')}</Nav.Link>
                      </LinkContainer>
                      */}
                      <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
                    </>
                  ) : (
                    <>
                      <LinkContainer to="/signupbasic">
                        <Nav.Link>{t('SIGNUP')}</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/loginbasic">
                        <Nav.Link>{t('LOGIN')}</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isAuthenticated ? (
                    <>
                      <Nav.Link onClick={handleLogout}>LOGOUT</Nav.Link>
                    </>
                  ) : (
                    <>
                      <LinkContainer to="/signupbasic">
                        <Nav.Link>{t('SIGNUP')}</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to="/loginbasic">
                        <Nav.Link>{t('LOGIN')}</Nav.Link>
                      </LinkContainer>
                    </>
                  )}
                </>
              )}
              <LangDropdown />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {'  '}{(user !== null) ? (user.nickname + ' ' + t('LOGINDISPLAY')) : ''}
        {isAuthenticated ? <SubNavigation currentLocation={currentLocation} /> : ""}
        <Navbar fixed="bottom" expand="md" className="navbar-dark bg-light px-2">
          <div className="col-sm-4">
          </div>
          <div className="col-sm-4">
            <div class="text-center">
              에러처리: <a href="https://github.com/Anemos/ERP_HISTORY/issues" target="_blank" rel="noopener noreferrer">이슈사항등록하기</a> <br />
              <a href="https://www.lukesoft.kr/"> lukesoft.co, ltd.</a> ⓒ 2021-{new Date().getFullYear()}, {t('VERSION')}
            </div>
          </div>
          <div className="col-sm-4">
            <Button variant="light" onClick={onClickToast}>
              <BsFillAlarmFill />
              {alarmCount != 0 && <Badge bg="danger">{alarmCount}</Badge>}
            </Button>
          </div>
        </Navbar>
        <AppContext.Provider value={providerValue}>
          <ServiceWorkerWrapper />
          <Routes />
        </AppContext.Provider>
      </div>
    )
  );
}

export default App;

