import React, { useRef, useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import DropdownSelector from "../components/DropdownSelector";
import { useTranslation } from 'react-i18next';
import TableDatePicker from "./TableDatePicker";
import * as dateFns from "date-fns";

function WorkCalendarInfo() {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [calendar, setCalendar] = useState(null);
  const [applydate, setApplydate] = useState(null);
  const [description, setDescription] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  
  useEffect(() => {
    async function onLoad() {
        await axiosInstance.get(`/CO/workcalendar/${id}/`).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          const calendar = response.data;
          setCalendar(calendar);
          setApplydate(new Date(calendar.applydate));
          setDescription(calendar.description);
        }).catch(function (error) {
            onError(error);
        });

    }
    
    onLoad();
  }, [id]);
  
  function validateForm() {
    return (
      applydate !== null &&
      description.length > 0
    );
  }
  
  async function saveCalendar(calendar) {
    console.log(calendar);
    axiosInstance.put(`/CO/workcalendar/${id}/`, calendar).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      onError(error);
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveCalendar({
        id: calendar.id,
        refyear: dateFns.getYear(applydate),
        applydate: dateFns.format(applydate,"yyyy-MM-dd'T'00:00:00"),
        isholiday: calendar.isholiday,
        apigubun: calendar.apigubun,
        description: description
      });
    
      history.push("/workcalendar");
    } catch (e) {
      onError(t('PROCESS_FAIL'));
      setIsLoading(false);
    }
  }

  function deleteCalendar() {
    return axiosInstance.delete(`/CO/workcalendar/${id}/`, calendar).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      return;
    }).catch(function (error) {
      onError(error);
      setIsLoading(false);
    }); 
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this data?"
    );

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteCalendar();
      
      history.push("/workcalendar");
    } catch (e) {
      onError(t('PROCESS_FAIL'));
      setIsDeleting(false);
    }
  }
  
  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          {calendar && (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="applydate">
                <Form.Label>휴무일</Form.Label>
                <TableDatePicker
                        wrapperClassName="datepicker"
                        selected={applydate}
                        onChange={setApplydate} 
                    />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>내용</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
              <LoaderButton
                block
                size="lg"
                variant="danger"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Delete
              </LoaderButton>
            </Form>
          )}
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default WorkCalendarInfo;