import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Alert,
    Grid
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlInputText } from "../../components/ControlInputText";
import { ControlInputTextMenuItem } from "../../components/ControlInputTextMenuItem";
import { ControlCheckbox } from "../../components/ControlCheckbox";
import { ControlDatePicker } from "../../components/ControlDatePicker";
import ItemBalanceTableFind from "../mm/ItemBalanceTableFind";
import DepartmentTableFind from "../hr/DepartmentTableFinde";
import CompanyMasterTableFind from "../mm/CompanyMasterTableFind";
import * as dateFns from "date-fns";

//example of creating a mui dialog modal for creating new rows
const WipCurrentTableCreate = ({ columns, selectedItemAppContext, setUpdateState, wipState }) => {
    const { register, handleSubmit, formState, getValues, setValue, control } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    const [wiocd, setWiocd] = useState("라인");
    const { isSubmitting } = formState;
    const [selectModalOpen, setSelectModalOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        //console.log(selectedItemAppContext);
        setValue("itembalance.id", selectedItemAppContext.id)
    }, [selectedItemAppContext]);

    const onChangeBillingForm = () => {
        const regex = /^[0-9\b -]{0,13}$/;
        var f_data = getValues()
        if (f_data.wiocd){
            setWiocd(f_data.wiocd);
        }
        //columns.map((column) => console.log(column.accessorKey));
        //console.log(f_data);
        // if (f_data.startdate){
        //     console.log(dateFns.format(f_data.startdate,"yyyy-MM-dd"));
        //     //setValue("startdate", dateFns.format(f_data.startdate,"yyyy-MM-dd"))
        // }
        // if (f_data.enddate){
        //     console.log(dateFns.format(f_data.enddate,"yyyy-MM-dd"));
        //     //setValue("enddate", dateFns.format(f_data.enddate,"yyyy-MM-dd"))
        // }
        // if (f_data.expiry){
        //     setValue("expiry", f_data.expiry.replace(/-/g, '').replace(/(\d{4})(\d{2})/, '$1-$2'))
        // }
        return;
    }

    const onSubmit = (data) => {
        //put your validation logic here
        console.log(data);
        // call axios post api
        axiosInstance.post(`/PP/wipcurrent/`, {
            itembalance: data.itembalance.id,
            wcompany: data.wcompany.id,
            wiocd: data.wiocd,
            wcost: 0,
            wbgqt: 0,
            wbgat: 0,
            winqt: 0,
            winat1: 0,
            winat2: 0,
            winat3: 0,
            wusqt1: 0,
            wusqt2: 0,
            wusqt3: 0,
            wusqt4: 0,
            wusqt5: 0,
            wusqt6: 0,
            wusqt7: 0,
            wusqt8: 0,
            wusat1: 0,
            wusat2: 0,
            wusat3: 0,
            wusat4: 0,
            wusat5: 0,
            wusat6: 0,
            wusat7: 0,
            wusat8: 0,
            wusat9: 0,
            wohqt: 0,
            wohat: 0,
            remarks: data.remarks ? data.remarks : "",
        }).then(function (response) {
            setUpdateState(new Date());
        }).catch(function (error) {
            //console.log(error);
            onError(error);
            setIsLoading(false);
        });
    };

    return (
        <>

                <Stack
                    sx={{
                        width: '100%',
                        minWidth: { xs: '260px', sm: '300px', md: '400px' },
                        gap: '1.5rem',
                    }}
                >
                    {(!formState.isValid && formState.isSubmitted) ?
                        <Alert severity="error"   >
                            {Object.values(formState.errors).map((e, idx) => {
                                return (<p key={idx}>{e.message}</p>)
                            })}
                        </Alert>
                        :
                        <Alert severity="success">재공 추가품목을 입력해 주세요</Alert>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                        {columns.map((column) => {
                            if (column.accessorKey == 'itembalance.id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "품번을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'wiocd') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputTextMenuItem
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "재공구분을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            defaultValue="라인"
                                            label={column.header}
                                            itemStatus={wipState}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'wcompany.id') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "업체을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                            defaultValue="0"
                                            disabled={true}
                                        />
                                    </Grid>
                                );
                            } else if (column.accessorKey == 'remarks') {
                                return (
                                    <Grid item xs={12} md={3}>
                                        <ControlInputText
                                            name={column.accessorKey}
                                            type="text"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: "특이사항을 입력해 주세요"
                                                },
                                                onChange: () => { onChangeBillingForm() }
                                            }}
                                            label={column.header}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                            
                            <Grid item xs={12} md={6}>
                                <DialogActions sx={{ p: '1.25rem' }}>
                                    <Button color="secondary" type="submit" variant="contained">
                                        재공 품목등록
                                    </Button>
                                </DialogActions>
                            </Grid>

                        </Grid>

                    </form>
                </Stack>

        <ItemBalanceTableFind
                setValue={setValue}
                columnName='itembalance.id'
            />
        {wiocd == "라인" ? (
            <DepartmentTableFind
            setValue={setValue}
            columnName='wdepartment.id'
        />
        ) : (
            <CompanyMasterTableFind
                setValue={setValue}
                columnName='wcompany.id'
            />
        )
        }
        </> 
    );
};

export default WipCurrentTableCreate;