import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationHR() {
    const { t } = useTranslation();

    return (

        <div className="bg-light border my-3">
            <Nav fill variant="pills">
                <LinkContainer to="/hrmanage" className="nav-link">
                    <Nav.Link>부서관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/employeemanage" className="nav-link">
                    <Nav.Link>직원관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/organization" className="nav-link">
                    <Nav.Link>조직관리</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/workcalendar" className="nav-link">
                  <Nav.Link>{t('ROBOCALENDAR')}</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/notes" className="nav-link">
                    <Nav.Link>공지사항관리</Nav.Link>
                </LinkContainer>
            </Nav>
        </div>

    );
}

export default SubNavigationHR;