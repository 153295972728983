import React from "react";
import { Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from 'react-i18next';
import "./SubNavigation.css";

function SubNavigationManager() {
  const { t } = useTranslation();
  
  return (

        <div className="bg-light border my-2">
          <Nav fill variant="pills">
              <LinkContainer to="/userdetail" className="nav-link">
                  <Nav.Link>내정보</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/settings" className="nav-link">
                  <Nav.Link>{t('SETTINGS')}</Nav.Link>
              </LinkContainer>
          </Nav>
        </div>

  );
}

export default SubNavigationManager;