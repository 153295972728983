import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Stack } from 'react-bootstrap';
import { useParams, useHistory, Link } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import { useTranslation } from 'react-i18next';
import ProductPlanMonthTable from "./ProductPlanMonthTable";

function ProductPlanMonthManage() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const { currentLocation, setCurrentLocation } = useAppContext();
    setCurrentLocation('/ppmanage');

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);


    return (
        <Stack gap={3}>
            <div className="bg-light">
                <ProductPlanMonthTable />
            </div>
        </Stack>
    );
}

export default ProductPlanMonthManage;