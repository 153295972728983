import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import axiosInstance from "../../axiosApi";
import { useTranslation } from 'react-i18next';
import MaterialReactTable from "material-react-table";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    InputLabel,
    Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import PurDetailTableCreate from "./PurDetailTableCreate";
import TableDatePickerEdit from "../TableDatePickerEdit";

function PurDetailTable() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const { selectedItemAppContext, setSelectedItemAppContext } = useAppContext();

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [tableData, setTableData] = useState([]);
    const {ingstatusState, setIngstatusState} = useAppContext();

    const [updateState, setUpdateState] = useState(new Date());
    const [rowSelection, setRowSelection] = useState({});
    const tableInstanceRef = useRef(null);

    useEffect(() => {
        async function onLoad() {

        }

        onLoad();
    }, []);

    useEffect(() => {
        //console.log(selectedItemBalanceAppContext.id);
        if (selectedItemAppContext) {
            setTableData([]);
            axiosInstance.get('/MM/purdetail/', {
                params: { purmaster_id: selectedItemAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                //console.log(response.data);
                setTableData(response.data);
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        }
    }, [selectedItemAppContext, updateState]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            const response = await axiosInstance.put(`/MM/purdetail/${values.id}/`, 
                {
                    purmaster: values['purmaster.id'],
                    itemcost: values['itemcost.id'],
                    deliverydate: values.deliverydate,
                    stockdate: values.stockdate ? values.stockdate : null,
                    requestqty: values.requestqty,
                    ingstatus: values.stockdate ? '완료' : '진행',
                    remarks: values.remarks,
                }
            );
            if (typeof response === 'undefined') throw "에러처리루틴 완료";
            setUpdateState(new Date());
            setIsLoading(false);
        }
        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`Are you sure you want to delete ${row.getValue('itemcost.itemmaster.itemname')}`)) {
                return;
            }
            axiosInstance.delete(`/MM/purdetail/${row.getValue('id')}/`, row.original).then(function (response) {
                if (typeof response === 'undefined') throw "에러처리루틴 완료";
                setUpdateState(new Date());
            }).catch(function (error) {
                setIsLoading(false);
            });
            setIsLoading(false);
        },
        [tableData],
    );


    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'crt_emp.email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id', //simple recommended way to define a column
                header: 'ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'purmaster.id', //simple recommended way to define a column
                header: '구매ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellProps: ({ cell }) => ({
                    onClick: () => {
                      console.log(cell.getValue(), cell.id);
                      //setSeletedBomParentItem(cell.getValue());
                    },
                  }),
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'itemcost.id', //simple recommended way to define a column
                header: '단가ID',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellProps: ({ cell }) => ({
                    onClick: () => {
                      console.log(cell.getValue(), cell.id);
                      //setSeletedBomParentItem(cell.getValue());
                    },
                  }),
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 50,
            },
            {
                accessorKey: 'itemcost.itemmaster.itemname', //simple recommended way to define a column
                header: '품명',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'deliverydate', //simple recommended way to define a column
                header: '납기일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="납기일자"
                    />
                )
            },
            {
                accessorKey: 'stockdate', //simple recommended way to define a column
                header: '입고일자',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Edit: ({ cell, row, table }) => (
                    <TableDatePickerEdit
                        wrapperClassName="datepicker"
                        cell={cell}
                        row={row}
                        labelText="입고일자"
                    />
                )
            },
            {
                accessorKey: 'requestqty', //simple recommended way to define a column
                header: '구매수량',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'requestamt', //simple recommended way to define a column
                header: '구매금액',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'ingstatus', //simple recommended way to define a column
                header: '진행상태',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'remarks', //simple recommended way to define a column
                header: '비고',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'serialno', //simple recommended way to define a column
                header: '전산번호',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                enableEditing: false, //disable editing on this column
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_emp.email', //simple recommended way to define a column
                header: 'Email',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
            {
                accessorKey: 'crt_date', //simple recommended way to define a column
                header: '등록일',
                enableEditing: false, //disable editing on this column
                muiTableHeadCellProps: { sx: { color: 'green' } }, //optional custom props
                Cell: ({ cell }) => <span>{cell.getValue()}</span>, //optional custom cell render
            },
        ],
        [getCommonEditTextFieldProps, ingstatusState],
    );

    return (
        <>
            <Box sx={{ p: 0.5, backgroundColor: '#607d8b'}}>
                <Typography variant="h6" color="white">구매품목정보</Typography>
            </Box>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                initialState={{
                    columnVisibility: { description: false },
                    density: 'compact',
                    showGlobalFilter: true,
                  }}
                enableColumnOrdering
                enableEditing
                enableMultiRowSelection={false} //use radio buttons instead of checkboxes
                enableRowSelection
                tableInstanceRef={tableInstanceRef}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                muiTableBodyRowProps={({ row }) => ({
                    //add onClick to row to select upon clicking anywhere in the row
                    onClick: row.getToggleSelectedHandler(),
                    sx: { cursor: 'pointer' },
                  })}
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                renderRowActions={({ row, table }) => (
                    !(row.original.ingstatus == "완료") && (<Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>)
                )}
            />
            <PurDetailTableCreate
                columns={columns}
                selectedItemAppContext={selectedItemAppContext}
                setUpdateState={setUpdateState}
                ingstatusState={ingstatusState}
            />
            
        </>
    );
}

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default PurDetailTable;