import React, { useState, useEffect } from "react";
import "./Home.module.css";
import { Accordion, Stack, Button, ListGroup, Image, Container, Col, Row } from 'react-bootstrap';
import Announcement from "../components/Announcement";
import { useAppContext } from "../libs/contextLib";
import axiosInstance from "../axiosApi";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LoaderButton from "../components/LoaderButton";
import erpimage from '../images/lukesoft_erp.png';

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 120px);
	grid-column-gap: 12px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

export default function Home() {
  const { isAuthenticated } = useAppContext();
  const { user, setUser } = useAppContext();
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentLocation, setCurrentLocation } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  setCurrentLocation('/');

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function clickHandler() {
    console.log("click comlete");
    const addBtn = document.querySelector('.add-button');
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  }

  const installCheck = () => {
    //let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    if (addBtn !== null) {
      addBtn.style.display = 'none';
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        //deferredPrompt = e;
        setDeferredPrompt(e);
        // Update UI to notify the user they can add to home screen
        addBtn.style.display = 'block';
      });
    }
  }

  useEffect(() => {
    async function onLoad() {
      try {
        //console.log(localStorage.getItem('access_token'));
      } catch (error) {
        //onError(error);
      }
    }

    onLoad();
    installCheck();
  }, []);

  async function handleRequestAuthority(event) {
    axiosInstance.get('/accounts/erp_authority/').then(function(response) {
      if (typeof response === 'undefined') throw "에러처리루틴 완료";
      alert(t('PROCESS_OK'));
      // add sign out login...
      localStorage.clear();
      userHasAuthenticated(false);
      setUser(null);
      history.push("/");
    }).catch(function (error) {
      setIsLoading(false);
    }); 
  }

  function renderHome() {
    return (
      <Stack gap={3}>
        <div>
          <Container>
            <Row>
              <p class="text-center">
                <h6>{t('HOME_002')}</h6>
                <b>현재 {
                  ((user !== null) ? user.user_type : false) ? user.user_type : 'GUEST'
                  } 사용권한을 가지고 계십니다.</b><br/>
                {((user !== null) ? user.user_type === 'ERPUSER' : false) ?
                (
                  <>
                    <b>ERP - 자재/구매 메뉴를 사용해 주세요</b>
                  </>
                )
                : 
                (
                  <>
                    <LoaderButton
                      block
                      size="lg"
                      variant="dark"
                      onClick={handleRequestAuthority}
                      isLoading={isLoading}
                    >
                      사용권한요청
                    </LoaderButton>  
                  </>)}
                <br />
              </p>
            </Row>
            {/*
            <Row>
              <Col xs={12} md={1}>
              </Col>
              <Col xs={12} md={10}>
                <Image src={erpimage} fluid />
              </Col>
              <Col xs={12} md={1}>
              </Col>
            </Row>
            */}
          </Container>
        </div>
        <div className="bg-light">
          <Announcement />
        </div>
        <div>
          <br /><br /><br />
        </div>
      </Stack>
    );
  }

  function renderIntro() {
    const onClickLoginHandler = () => {
      history.push("/loginbasic");
    };

    const onClickSingupHandler = () => {
      history.push("/signupbasic");
    };

    return (
      <Stack gap={3}>
        {/*
        <button className="add-button" onClick={clickHandler}>Raxi앱 설치하기</button>
        */}
        <div className="card" >
          <div className="card-body">
            <div className="row">
              <div className="col-xs-2">
              </div>
              <div className="col-xs-8">
                <p class="text-center">
                  <h6>{t('HOME_002')}</h6>
                  <h6>{t('HOME_003')}</h6>
                  <br />
                </p>
              </div>
              <div className="col-xs-2">
              </div>
            </div>
            <br />
            <br />

            <div className="row">
              <div className="col-3">
              </div>
              <div>
                <FlexContainer>
                  <GridContainer>
                    <Button variant="outline-dark" size="lg" onClick={onClickSingupHandler}><strong>{t('SIGNUP')}</strong></Button>
                    <Button variant="outline-dark" onClick={onClickLoginHandler}><strong>{t('LOGIN')}</strong></Button>
                  </GridContainer>
                </FlexContainer>
              </div>
              {/* <div className="col-6">
                <Button variant="outline-dark" onClick={onClickSingupHandler}><strong>{t('SIGNUP')}</strong></Button>
                <Button variant="outline-dark" onClick={onClickLoginHandler}><strong>{t('LOGIN')}</strong></Button>
              </div> */}
              <div className="col-3">
              </div>
            </div>
          </div>
        </div>
        {/*
        <div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header><span><i class="fa fa-bookmark"></i> {t('COMPANYINFO')}</span></Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>{t('COMPANYINFO1')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO2')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO3')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO4')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO5')}</ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        */}
        <Announcement />
        <div>
          <br /><br /><br />
        </div>
      </Stack>

    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderHome() : renderIntro()}
    </div>
  );
}